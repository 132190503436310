import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { Group, Groups } from "../../MobileViewEditor/flowTypes";

export const getGroupByCmpId = (groups: Groups = {}, cmpId: string, componentsMap: ComponentsMap): null | undefined |  Group => {
    const cmpGroupId = Object.keys(groups).find(groupId => groups[groupId].find(id => id === cmpId));
    if (!cmpGroupId) {
        return null;
    }
    return groups[cmpGroupId].map(id => componentsMap[id]);
};

export const getGroupIdByCmpId = (groups: Groups = {}, cmpId: string): null | undefined |  string => {
    const cmpGroupId = Object.keys(groups).find(groupId => groups[groupId].find(id => id === cmpId));
    if (!cmpGroupId) {
        return null;
    }
    return cmpGroupId;
};
