import type { Reducer as ReduxReducer, Store } from 'redux';

import type { SiteSettings } from '../../../../wbtgen/src/components/App/epics/siteSettings/flowTypes';
import type { SubscriptionData } from '../../../../wbtgen/src/redux/modules/flowTypes';
import type { DataSite } from "../../../../wbtgen/dal/model/DataSite";
import type { WhatsNewState } from '../../../../wbtgen/src/components/WhatsNew/types';
import type {
    TrackingGlobalDataType
} from "../../../../wbtgen/src/components/SiteSettings/trackingGlobalData/trackingGlobalDataEpic";
import type { KlikenSiteStatsSummaryType } from "../../../../wbtgen/dal/flowTypes";
import type { UserData } from "../../../../wbtgen/src/components/App/flowTypes";
import type { ResourceMetadata } from "../../../../wbtgen/src/redux/modules/children/fileChooser/flowTypes";
import type { ServerPublishType } from "../components/Publish/types";
import type { PreferencesState } from '../components/Preferences/types';
import type { MarketgooState } from '../components/Pages/Marketgoo/types';
import type { ThirdPartyConnectionsState } from '../components/ThirdPartyConnections/types';
import type { SocialAccountsType } from "../../../../wbtgen/src/components/SocialAccounts/types";
import type { GeneralInfoTabData } from "../../../../wbtgen/src/components/SiteSettings/General/types";
import type { BusinessListingSettingsType } from '../components/Pages/BusinessListing/redux/types';
import { OnlineShopType } from '../components/Pages/OnlineShop/types';
import { PrivacyPolicyState } from '../components/Pages/PrivacyPolicy/types';

export type LoginState = {
    domain: string;
    email: string;
};
export type OwnerEmailConfig = Array<string> | null | undefined;
export type OwnerInfo = UserData;
export type AppStatusState = {
    type: string;
    data?: string;
};
export type SiteSettingsType = SiteSettings;
export type SiteDataType = DataSite;
export type OwnerInfoType = {
    email?: OwnerEmailConfig;
    userData: OwnerInfo | null | undefined;
    loading: boolean;
    failed: boolean;
    retryCount: number;
    adminIdFetchFailed: boolean;
};
export type TrackingDataType = TrackingGlobalDataType;
export type TopBarLanguagesState = {
    current: string;
    map: Record<string, string>;
};
export type TopBarState = {
    languages: TopBarLanguagesState;
};
export type SideBarState = {
    isMobileSideBarOpen: boolean;
    selectedSideBarItemId: string;
    selectedSideBarItemData?: any;
};
export type OverviewStepsState = {
    stepsStatus: Record<string, boolean>;
};
export type OverviewCompanionState = {
    selectedDevice: string;
};
export type ActiveDialogType = {
    id: string;
    props: any;
};
export type AnalyticsStatsSummaryType = {
    value?: KlikenSiteStatsSummaryType;
    lastUpdated: string | Date;
    error: boolean;
    deleted?: boolean;
};
export type AnalyticsStateType = {
    authToken?: string;
    statsSummary?: AnalyticsStatsSummaryType;
};
export type RawResource = { [any: string]: any }
export type imageChooserType = {
    isResourcesLoaded: boolean,
    isAssetMetaDataLoaded: boolean,
    resources: Array<RawResource>,
    asset: ResourceMetadata | {},
    assetPath: string | null,
}
export enum SaveStatusEnum {
    CAN_SAVE = 'CAN_SAVE',
    CAN_NOT_SAVE = 'CAN_NOT_SAVE',
    IN_PROGRESS = 'IN_PROGRESS'
}
export type SaveStatusChanges = {
    siteSettings: boolean;
    siteData: boolean;
    thirdPartyConnections: boolean;
};
export type SaveStatusState = {
    status: SaveStatusEnum;
    changes: SaveStatusChanges;
};
export type GoogleAdsSettingsState = {
    isSiteWitEnabled: boolean | null;
    isLoading: boolean;
};
export type BannersState = {
    /**
     *  list of dismissed banners.
     *  to be initialized from localStorage / sessionStorage.
     *
     *  takes highest priority, if banner is hidden here it won't be displayed
     *  regardless of other flags.
     */
    hiddenBanners: Record<string, boolean>;
    /**
     *  list of banners that are requested to be displayed.
     *  banners can be requested from anywhere in the app (middlewares, components, ...).
     *
     *  components can access the relevant banner id flag, and handle it accordingly.
     */
    pendingBanners: Record<string, boolean>;
};
export type AppState = {
    privacyPolicy: PrivacyPolicyState;
    appName: string;
    login: LoginState;
    appStatus?: AppStatusState;
    siteSettings: SiteSettings;
    siteData: SiteDataType;
    ownerInfo: OwnerInfoType;
    topBar: TopBarState;
    sideBar: SideBarState;
    publish?: ServerPublishType;
    subscriptionData: SubscriptionData;
    overviewSteps?: OverviewStepsState;
    overviewCompanion?: OverviewCompanionState;
    preferences: PreferencesState;
    whatsNew: WhatsNewState;
    analytics: AnalyticsStateType;
    chat: any;
    dialog?: any;
    saveStatus: SaveStatusState;
    googleAds: GoogleAdsSettingsState;
    imageChooser: imageChooserType;
    marketgoo: MarketgooState;
    thirdPartyConnections: ThirdPartyConnectionsState;
    banners: BannersState;
    socialAccounts: SocialAccountsType;
    generalInfo: GeneralInfoTabData;
    businessListing: BusinessListingSettingsType;
    onlineShop: OnlineShopType;
    epics?: any;
};
export type Middleware = (
    store: Store<AppState>,
) => (next: Dispatch) => (action: Action) => Action;
export type Reducer<T = any> = ReduxReducer<T, Action<T>>;
export type ReducerConfig = {
    reducer: Reducer;
    global?: boolean;
};
