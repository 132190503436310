
import view from "./view/index";
import kind from "./kind";
import calcRenderProps from './calcRenderProps';
import type { InstagramGalleryComponent } from './flowTypes';
import type { CalcProps, CmpSpecificStyles } from "../../Preview/flowTypes";
import { getScaledGalleryImageStyles } from "../../../utils/getScaledStyles";

const defaultSocialAccountsState = {
    instagram: {
        instagramAssetUrls: [],
        instagramAssetsLoading: false
    }
};

export default {
    kind,
    view,
    calcProps: ({
        componentId, component, siteMap, globalStyles, globalVariables, previewBackupTime, componentsDependencies,
        isServerPreview, stylesheetsIdToNameMap, siteSettings: { themeSettingsData }, selectedParentTheme,
    }: CalcProps<InstagramGalleryComponent>) => calcRenderProps({
        componentId,
        component,
        componentExtension: {},
        componentDependencies: {
            site: siteMap,
            missingAssetUrls: [],
            globalStyles,
            globalVariables,
            siteFonts: [],
            templateWidth: 0,
            defaultStyleId: componentsDependencies?.[kind] ? componentsDependencies[kind].defaultStyleId : {},
            stylesheetsIdToNameMap,
            socialAccounts: componentsDependencies?.[kind] ? componentsDependencies[kind].socialAccounts : defaultSocialAccountsState,
            themeSettingsData,
        },
        isWorkspace: false,
        previewBackupTime,
        inEditMode: false,
        isServerPreview,
        selectedParentTheme,
    }),
    getSpecificStyles: ({ component, widthInMobile }: CmpSpecificStyles<InstagramGalleryComponent>): string => {
        const scaledStyles = getScaledGalleryImageStyles(component),
            { id, mobileSettings: { spacingPx: mobileSpacing, columns: mobileColumns } } = component,
            mobileStyle = (widthInMobile) ?
                `.mobileV div[data-id='${id}'] .instagram-gallery img {
                    height: ${mobileColumns === 1 ? widthInMobile : (widthInMobile - mobileSpacing) / mobileColumns}px !important;
                    width: 100% !important;
                }` : '';
        return `
            ${scaledStyles}
            ${mobileStyle}
        `;
    },
    js: [
        '/renderStatic/lightbox/shinybox/jquery.shinybox.modified.js',
        '/renderStatic/instagramGallery/populateForPublish.js',
    ],
    css: [
        '/renderStatic/lightbox/shinybox/shinybox.css',
        '/renderStatic/lightbox/lightbox.css'
    ]
};
