import CALL_API from "../../../../../../wbtgen/src/redux/middleware/api/CALL_API";

export { APP_STATUS_READY } from "../../../app/actionTypes";

export const
    OPEN_SHOP_ROUTE_ACTION = 'OPEN_SHOP_ROUTE_ACTION',
    INIT_SHOP_ROUTE_IN_DIALOG_ACTION = 'INIT_SHOP_ROUTE_IN_DIALOG_ACTION',
    SET_SHOP_ROUTE_IN_DIALOG_ACTION = 'SET_SHOP_ROUTE_IN_DIALOG_ACTION',
    OPEN_SHOP_DIALOG_ACTION = 'OPEN_SHOP_DIALOG_ACTION',
    CLOSE_SHOP_DIALOG_ACTION = 'CLOSE_SHOP_DIALOG_ACTION',
    OPEN_SHOP_HOME_ACTION = 'OPEN_SHOP_HOME_ACTION',
    OPEN_SHOP_OPEN_ORDERS_ACTION = 'OPEN_SHOP_OPEN_ORDERS_ACTION',
    OPEN_SHOP_ALL_PRODUCTS_ACTION = 'OPEN_SHOP_ALL_PRODUCTS_ACTION',
    OPEN_SHOP_OUT_OF_STOCK_PRODUCTS_ACTION = 'OPEN_SHOP_OUT_OF_STOCK_PRODUCTS_ACTION',
    OPEN_SHOP_CREATE_FIRST_PRODUCT_ACTION = 'OPEN_SHOP_CREATE_FIRST_PRODUCT_ACTION',

    CHECK_ONLINE_SHOP_SETUP_REQUEST_ACTION = 'CHECK_ONLINE_SHOP_SETUP_REQUEST_ACTION',
    CHECK_ONLINE_SHOP_SETUP_SUCCESS_ACTION = 'CHECK_ONLINE_SHOP_SETUP_SUCCESS_ACTION',
    CHECK_ONLINE_SHOP_SETUP_FAILURE_ACTION = 'CHECK_ONLINE_SHOP_SETUP_FAILURE_ACTION',

    LOAD_SHOP_STATS_ACTION = "LOAD_SHOP_STATS_ACTION",

    GET_OPEN_ORDERS_REQUEST_ACTION = 'GET_OPEN_ORDERS_REQUEST_ACTION',
    GET_OPEN_ORDERS_SUCCESS_ACTION = 'GET_OPEN_ORDERS_SUCCESS_ACTION',
    GET_OPEN_ORDERS_FAILURE_ACTION = 'GET_OPEN_ORDERS_FAILURE_ACTION',

    GET_OUT_OF_STOCK_PRODUCTS_REQUEST_ACTION = 'GET_OUT_OF_STOCK_PRODUCTS_REQUEST_ACTION',
    GET_OUT_OF_STOCK_PRODUCTS_SUCCESS_ACTION = 'GET_OUT_OF_STOCK_PRODUCTS_SUCCESS_ACTION',
    GET_OUT_OF_STOCK_PRODUCTS_FAILURE_ACTION = 'GET_OUT_OF_STOCK_PRODUCTS_FAILURE_ACTION',

    GET_PRODUCTS_REQUEST_ACTION = 'GET_PRODUCTS_REQUEST_ACTION',
    GET_PRODUCTS_SUCCESS_ACTION = 'GET_PRODUCTS_SUCCESS_ACTION',
    GET_PRODUCTS_FAILURE_ACTION = 'GET_PRODUCTS_FAILURE_ACTION',

    GET_REVENUE_REQUEST_ACTION = 'GET_REVENUE_REQUEST_ACTION',
    GET_REVENUE_SUCCESS_ACTION = 'GET_REVENUE_SUCCESS_ACTION',
    GET_REVENUE_FAILURE_ACTION = 'GET_REVENUE_FAILURE_ACTION';

export const openShopRouteAction = (route: string) => ({ type: OPEN_SHOP_ROUTE_ACTION, payload: { route } });
export const initShopRouteInDialogAction = (route: string) => ({ type: INIT_SHOP_ROUTE_IN_DIALOG_ACTION, payload: { route } });
export const setShopRouteInDialogAction = (route: string) => ({ type: SET_SHOP_ROUTE_IN_DIALOG_ACTION, payload: { route } });
export const openShopDialogAction = () => ({ type: OPEN_SHOP_DIALOG_ACTION });
export const closeShopDialogAction = (isShopLoaded: boolean) => ({ type: CLOSE_SHOP_DIALOG_ACTION, payload: { isShopLoaded } });

export const openShopHomeAction = () => ({ type: OPEN_SHOP_HOME_ACTION });
export const openShopOpenOrdersAction = () => ({ type: OPEN_SHOP_OPEN_ORDERS_ACTION });
export const openShopAllProductsAction = () => ({ type: OPEN_SHOP_ALL_PRODUCTS_ACTION });
export const openShopOutOfStockProductsAction = () => ({ type: OPEN_SHOP_OUT_OF_STOCK_PRODUCTS_ACTION });
export const openShopCreateFirstProductAction = () => ({ type: OPEN_SHOP_CREATE_FIRST_PRODUCT_ACTION });

export const
    checkOnlineShopSetupAction = () => ({
        [CALL_API]: {
            types: [
                CHECK_ONLINE_SHOP_SETUP_REQUEST_ACTION,
                CHECK_ONLINE_SHOP_SETUP_SUCCESS_ACTION,
                CHECK_ONLINE_SHOP_SETUP_FAILURE_ACTION
            ],
            endpoint: 'checkIfWebshopIsSetup',
            retryCount: 2
        }
    } as unknown as Action),
    loadShopStatsAction = () => ({ type: LOAD_SHOP_STATS_ACTION }),
    getOpenOrdersAction = () => ({
        [CALL_API]: {
            types: [
                GET_OPEN_ORDERS_REQUEST_ACTION,
                GET_OPEN_ORDERS_SUCCESS_ACTION,
                GET_OPEN_ORDERS_FAILURE_ACTION
            ],
            endpoint: 'getWebshopData',
            endpointParams: '/order?pageSize=1&pageNumber=1&orderStatus=OPEN&archived=false',
            retryCount: 2,
            retryDelay: 100
        }
    } as unknown as Action),
    getOutOfStockProductsAction = () => ({
        [CALL_API]: {
            types: [
                GET_OUT_OF_STOCK_PRODUCTS_REQUEST_ACTION,
                GET_OUT_OF_STOCK_PRODUCTS_SUCCESS_ACTION,
                GET_OUT_OF_STOCK_PRODUCTS_FAILURE_ACTION
            ],
            endpoint: 'getWebshopData',
            endpointParams: '/product?archived=false&stock=OUT_OF_STOCK&pageSize=1&pageNumber=1',
            retryCount: 2,
            retryDelay: 100
        }
    } as unknown as Action),
    getRevenueAction = () => ({
        [CALL_API]: {
            types: [
                GET_REVENUE_REQUEST_ACTION,
                GET_REVENUE_SUCCESS_ACTION,
                GET_REVENUE_FAILURE_ACTION
            ],
            endpoint: 'getRevenue',
            endpointParams: {
                days: 7
            },
            retryCount: 2,
            retryDelay: 100
        }
    } as unknown as Action),
    getProductsAction = () => ({
        [CALL_API]: {
            types: [
                GET_PRODUCTS_REQUEST_ACTION,
                GET_PRODUCTS_SUCCESS_ACTION,
                GET_PRODUCTS_FAILURE_ACTION
            ],
            endpoint: 'getWebshopData',
            endpointParams: '/product?archived=false&pageSize=1&pageNumber=1',
            retryCount: 2,
            retryDelay: 100
        }
    } as unknown as Action);
