
/* eslint-disable max-len */
import view from "./view/index";
import kind from "./kind";
import calcRenderProps from './calcRenderProps';
import type { GalleryComponent } from './flowTypes';
import type { CalcProps, CmpSpecificStyles } from "../../Preview/flowTypes";
import { getScaledGalleryImageStyles } from "../../../utils/getScaledStyles";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

export default {
    kind,
    view,
    calcProps: ({
        componentId,
        component,
        hdImages,
        siteMap,
        globalStyles,
        globalVariables,
        previewBackupTime,
        siteSettings: { themeSettingsData },
        template,
        selectedParentTheme,
        componentsDependencies
    }: CalcProps<GalleryComponent>) => calcRenderProps({
        componentId,
        component,
        componentExtension: {},
        componentDependencies: {
            hdImages,
            site: siteMap,
            missingAssetUrls: [],
            globalStyles,
            globalVariables,
            siteFonts: [],
            templateWidth: template.width,
            themeColorsData: getThemeColorsFromStylesheet(globalStyles),
            themeSettingsData,
            templateData: template,
            workspaceBBoxWidth: (componentsDependencies?.GALLERY) ? componentsDependencies.GALLERY.workspaceBBoxWidth : 3000,
            workspaceBBoxLeft: (componentsDependencies?.GALLERY) ? componentsDependencies.GALLERY.workspaceBBoxLeft : -300,
        },
        isWorkspace: false,
        previewBackupTime,
        inEditMode: false,
        selectedParentTheme,
    }),
    getSpecificStyles: ({ component, template }: CmpSpecificStyles<GalleryComponent>): string => {
        let styles = getScaledGalleryImageStyles(component) || '';

        if (component.stretch) {
            styles += `
                body.desktopV div.template:not(.mobileV) div[data-id="${component.id}"][data-specific-kind="GALLERY"][data-stretch="true"] {
                    display: flex;
                    justify-content: center;
                    align-content: flex-start;
                    align-items: flex-start;
                }
                body.desktopV div.template:not(.mobileV) div[data-id="${component.id}"][data-specific-kind="GALLERY"][data-stretch="true"] div.gallery {
                    width: ${100 - (component.fullWidthOption.margin * 2)}%;
                    max-width: ${component.fullWidthOption.maxWidth}px;
                    min-width: ${template.width}px;
                    flex: ${component.fullWidthOption.margin ? '0 1 calc(' + (100 - (component.fullWidthOption.margin * 2)) + '% + ' + component.spacingPx + 'px)' : '1'};
                    gap: ${component.spacingPx}px;
                }

                body.desktopV div.template:not(.mobileV) div[data-id="${component.id}"][data-specific-kind="GALLERY"][data-stretch="true"] div.gallery div.gallery-cell {
                    flex-basis: calc((100% - ${component.spacingPx * (component.columns - 1)}px) / ${component.columns});
                    max-width: calc((100% - ${component.spacingPx * (component.columns - 1)}px) / ${component.columns});
                    margin: 0 !important;
                }
            `;
        }
        return styles;
    },
    js: [
        '/renderStatic/lightbox/shinybox/jquery.shinybox.modified.js',
        '/renderStatic/lightbox/lightbox_wbtgen.js',
        '/renderStatic/gallery/gallery.js',
        '/renderStatic/gallery/gallery_pullup.js'
    ],
    css: [
        '/renderStatic/lightbox/shinybox/shinybox.css',
        '/renderStatic/lightbox/lightbox.css'
    ]
};
