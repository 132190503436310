import * as React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import { SOCIAL_KIND as SocialKind } from "../../../oneweb/Social/kind";
import SocialView from "../../../oneweb/Social/view/SocialLeftPanelView";
import FacebookKind from "../../../oneweb/Facebook/kind";
import * as Icon from '../Icons.css';
import { Social } from "../constants";
import * as styles from '../../view/Inserter.css';
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { EPSeparator } from "../../view/EPSeparator";
import { ComponentNames } from "../../../oneweb/constants";
import { DroppableCmpShortcut } from "../../view/DroppableCmpShortcut";
import type { ExtendedPanelContentProps } from "../../view/inserter";
import { SocialColorSourceType, SocialStyleType } from "../../../oneweb/Social/constants";
import InstagramGalleryKind from "../../../oneweb/InstagramGallery/kind";
import Msg from "../../../../view/intl/Msg";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import stylesheetsEpic from "../../../Workspace/epics/stylesheets/index";
import { colorThemeSiteSettingsEpic } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import type { ThemeSiteSettingsDataType } from '../../../ThemeGlobalData/flowTypes';
import type { Stylesheets } from '../../../Workspace/epics/stylesheets/flowTypes';
import FacebookFeedGalleryKind from "../../../oneweb/FacebookFeedGallery/kind";
import { getThemeColorsFromStylesheet } from "../../../Workspace/epics/stylesheets/selectors";
import { checkSubscriptionCompatibilityFromKind } from "../../../ComponentTierManager/utils";
import { isTrialSubscription } from "../../../App/epics/subscriptionData/utils";
import { PremiumIcon } from "../../../../view/common/PremiumIcon";
import handleDisableTip from "../../ToolTip/handleDisableIconTip";
import disableFBComponents from '../../../../utils/disableFBComponents';
import { isDemoSubscriptionTypeAppSel, trialUserEmailSel } from "../../../../../demo/modules/selectors";
import ReviewsInserter from './ReviewsInserter';
import isInstagramComponentDisabled from '../../../../utils/disableInstagramComponent';
import { AppState } from '../../../../redux/modules/flowTypes';
import getFeatureManager from "../../../../getFeatureManager";

const InstagramInserter = (props: ExtendedPanelContentProps) => {
    const
        isUserPremiumCompatible = checkSubscriptionCompatibilityFromKind(
            InstagramGalleryKind, props.subscriptionType
        ),
        mouseHoverHandler = handleDisableTip({
            dispatch: props.dispatch,
            id: getFeatureManager().isInstagramComponentEnabled() ?
                'InstagramForceDisabled' :
                `GenericFeatureDisabled`
        }),
        showPremiumIcon = !isUserPremiumCompatible || isTrialSubscription(props.subscriptionType);

    if (isInstagramComponentDisabled()) {
        return (
            <div className={cx(styles.socialMediaFeedInserter, styles.disableFeature)} {...mouseHoverHandler}>
                <div className={styles.socialMediaFeedLogoContainer}>
                    <div className={cx(styles.instagramLogo)} />
                </div>
                <div className={styles.socialMediaFeedDescriptionContainer}>
                    <Msg k="common.instagramGallery" className={styles.socialMediaFeedTitle}>Instagram gallery</Msg>
                    <br />
                    <Msg k="common.instagramGallery.subtitle" className={styles.socialMediaFeedSubtitle}>Display your Instagram feed</Msg>
                </div>
            </div>
        );
    }

    return (
        <DroppableCmpShortcut {...props} kind={InstagramGalleryKind} className={styles.overlayLittleMoreExtended}>
            <div className={styles.socialMediaFeedInserter}>
                <div className={styles.socialMediaFeedLogoContainer}>
                    {showPremiumIcon && <PremiumIcon iconCustomClass={styles.premiumSocialMediaIcon} />}
                    <div className={cx(styles.instagramLogo)} />
                </div>
                <div className={styles.socialMediaFeedDescriptionContainer}>
                    <Msg k="common.instagramGallery" className={styles.socialMediaFeedTitle}>Instagram gallery</Msg>
                    <br />
                    <Msg k="common.instagramGallery.subtitle" className={styles.socialMediaFeedSubtitle}>Display your Instagram feed</Msg>
                </div>
            </div>
        </DroppableCmpShortcut>
    );
};

const FacebookFeedInserter = (props: ExtendedPanelContentProps) => {
    const
        isUserPremiumCompatible = checkSubscriptionCompatibilityFromKind(
            FacebookFeedGalleryKind, props.subscriptionType
        ),
        showPremiumIcon = !isUserPremiumCompatible || isTrialSubscription(props.subscriptionType),
        mouseHoverHandler =  handleDisableTip({
            dispatch: props.dispatch,
            id: getFeatureManager().isFacebookComponentEnabled() ?
                `${FacebookFeedGalleryKind}ForceDisabled` :
                `GenericFeatureDisabled`
        }),
        getFBInserterDiv = () => {
            return (
                <React.Fragment>
                    <div className={styles.socialMediaFeedLogoContainer}>
                        { showPremiumIcon && <PremiumIcon iconCustomClass={styles.premiumSocialMediaIcon} />}
                        <div className={cx(styles.facebookFeedLogo)} />
                    </div>
                    <div className={styles.socialMediaFeedDescriptionContainer}>
                        <Msg
                            k="common.facebookFeedGallery"
                            className={styles.socialMediaFeedTitle}
                        >Facebook feed gallery</Msg>
                        <br />
                        <Msg
                            k="common.facebookFeedGallery.subtitle"
                            className={styles.socialMediaFeedSubtitle}
                        >Display your Facebook feed</Msg>
                    </div>
                </React.Fragment>
            );
        };
    // WBTGEN-28365 Added handling to enable FB component only for whitelisted domains or for all
    // if no whitelisting
    const demoData = {
        isDemo: props.isDemo,
        email: props.email
    };
    // @ts-ignore
    if (disableFBComponents(demoData)) {
        return (
            <div className={cx(styles.socialMediaFeedInserter, styles.disableFeature)} {...mouseHoverHandler}>
                {getFBInserterDiv()}
            </div>
        );
    }
    return (
        <DroppableCmpShortcut {...props} kind={FacebookFeedGalleryKind} className={styles.overlayLittleMoreExtended}>
            <div className={styles.socialMediaFeedInserter}>
                {getFBInserterDiv()}
            </div>
        </DroppableCmpShortcut>
    );
};

const Content = connect((appState: AppState) => ({
    themeSettingsData: makeEpicStateSelector(colorThemeSiteSettingsEpic.valueActionType)(appState),
    stylesheets: makeEpicStateSelector(stylesheetsEpic.valueActionType)(appState),
    isDemo: isDemoSubscriptionTypeAppSel(appState),
    email: trialUserEmailSel(appState)
}))((props: ExtendedPanelContentProps & {
    themeSettingsData: ThemeSiteSettingsDataType;
    stylesheets: Stylesheets;
}) => {
    const themeColorsData = getThemeColorsFromStylesheet(props.stylesheets);
    return (<div>
        <VerticalSpacer y={20} />
        <div className={styles.subTitle}>{props.intl.msgJoint(ComponentNames[SocialKind])}</div>
        <div className={styles.socialCmpsContainer}>
            <DroppableCmpShortcut
                {...props}
                kind={SocialKind}
                isOverlayExtended
            >
                <SocialView themeColorsData={themeColorsData} themeSettingsData={props.themeSettingsData} />
            </DroppableCmpShortcut>
            <DroppableCmpShortcut
                {...props}
                kind={SocialKind}
                payload={{ colorType: SocialColorSourceType.DARK, styleType: SocialStyleType.CIRCLE_FILL }}
                isOverlayExtended
            >
                <SocialView dark round themeColorsData={themeColorsData} themeSettingsData={props.themeSettingsData} />
            </DroppableCmpShortcut>
        </div>
        <EPSeparator />
        <VerticalSpacer y={20} />
        <div className={styles.subTitle}>{props.intl.msgJoint(ComponentNames[FacebookKind])}</div>
        <VerticalSpacer y={20} />
        <DroppableCmpShortcut {...props} kind={FacebookKind} className={styles.overlayLittleExtended}>
            <div className={Icon.facebookScreenShot} />
        </DroppableCmpShortcut>

        <EPSeparator />
        <VerticalSpacer y={20} />
        <div className={styles.subTitle}>{props.intl.msgJoint('msg: common.socialGalleries {Social galleries}')}</div>
        <VerticalSpacer y={20} />
        <InstagramInserter {...props} />
        <VerticalSpacer y={30} />
        <FacebookFeedInserter {...props} />
        <VerticalSpacer y={20} />

        <ReviewsInserter {...props} />
    </div>);
});

export default {
    kind: SocialKind,
    shortCut: {
        iconClassName: Icon.social,
        text: Social
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.socialTitle {Social}',
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
