type MaybeBoolean = boolean | null | undefined;

export const
    shouldAllowArbitraryEmail = (
        allowArbitraryEmail: boolean,
        partnerIsHostnet: boolean,
        isOneHopClient: MaybeBoolean,
    ): boolean => {
        if (allowArbitraryEmail) {
            // From configuration
            return true;
        } else if (partnerIsHostnet === true && isOneHopClient === false) {
            // From configuration and server api response
            return true;
        }

        return false;
    };
