import { take, fork, cancel } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import registry from '../view/oneweb/registry/index';
import appSaga from '../components/App/saga/index';
import fontsSaga from '../components/Fonts/saga';
import fileChooserSaga from './modules/children/fileChooser/saga';
import checkUnsavedChangesSaga from '../components/UnsavedChanges/checkUnsavedChangesSaga';
import backupSaga from '../components/Backup/saga/index';
import imageEditorSaga from '../components/ImageEditor/saga';

const sagas = Object
    .keys(registry)
    .map(key => registry[key].saga)
    .filter(saga => saga !== undefined);

sagas.push(appSaga);
sagas.push(backupSaga);
sagas.push(imageEditorSaga);
sagas.push(fontsSaga);
sagas.push(fileChooserSaga);
sagas.push(checkUnsavedChangesSaga);

const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga: any) {
    if (process.env.NODE_ENV === 'development') {
        return function* main() {
            const sagaTask = yield fork(saga);

            yield take(CANCEL_SAGAS_HMR);

            if (sagaTask) {
                yield cancel(sagaTask);
            }
        };
    } else {
        return saga;
    }
}

const SagaManager = {
    startSagas(sagaMiddleware: any) {
        sagas.forEach(saga => sagaMiddleware.run(createAbortableSaga(saga)));
    },

    cancelSagas(store: Store) {
        store.dispatch({
            type: CANCEL_SAGAS_HMR
        });
    }
};

export { SagaManager as default, CANCEL_SAGAS_HMR };
