import * as R from 'ramda';
import onecolor from "onecolor";
import { Style } from "@one.com/radium-one.com";
import {
    stylesheetByIdSelector,
    textNormalGlobalstyle,
    getThemeColorsFromStylesheet,
    getButtonStyleById,
    getFirstButtonStyle,
    getFirstLinkStyle,
    getFirstStylesheetByType,
    getStyleId,
} from "../../Workspace/epics/stylesheets/selectors";
import WebShopComponentKind from "./kind";
import { DataSite } from "../../../../dal/model/index";
import type { Stylesheets, ButtonStylesheet, Stylesheet } from "../../Workspace/epics/stylesheets/flowTypes";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import { color, inactive, block, background, colorData, id } from "../../../mappers/path";
import {
    BUTTON_THEME_PRIMARY,
    THEME_BUTTON_CLASS
} from "../../ThemeGlobalData/constants";

import * as colorMapper from "../../../mappers/color";
import {
    themeLinkAccentBase,
    themeRulesBackgroundForLinkColor,
    getThemeRulesForWebshop,
    getThemeRulesForBackground,
} from '../../ThemeGlobalData/themeRules';
import LinkMapper from "../Link/globalStyle/mapper";
import type { ThemeBackgroundType, ThemeColorDataType, ThemeButtonTypes } from "../../ThemeGlobalData/flowTypes";

import { reducerDefaultData } from "./reducer/reducerDefaultData";
import type { WebshopComponent } from "./flowTypes";
import type { FeaturedProductsComponent } from "../FeaturedProducts/flowTypes";
import { getGlobalstyleClassNameFromStyle } from "../../RenderGlobalstyles/getGlobalstyleClassName";
import ButtonMapper from '../Button/globalStyle/mapper';
import { getThemeStyleForButtonWithKnownTheme } from "../Button/utils";
import ButtonGlobalStyleKind from '../Button/globalStyle/kind';
import { getAppConfig } from '../../App/epics/appConfig/appConfig';
import AppConfig from "../../../utils/AppConfig";
import { getMergedWebshopAppShopfrontBaseUrl } from '../../../../../server/shared/webshop/utils';

export const getButtonStyleId = (
    webshopButtonStyleId: null | undefined | string,
    globalStyles: Stylesheets
): string => {
    const defaultButtonStyleId = getStyleId(
        getFirstStylesheetByType(ButtonGlobalStyleKind)(globalStyles)
    );
    if (webshopButtonStyleId) {
        if (stylesheetByIdSelector(webshopButtonStyleId)(globalStyles)) {
            return webshopButtonStyleId;
        } else {
            return defaultButtonStyleId;
        }
    }
    return defaultButtonStyleId;
};

export const findWebshopComponent = (componentsMap: ComponentsMap) =>
        R.filter(comp => comp.kind === WebShopComponentKind)(componentsMap),
    isWebshopComponentFound = (componentsMap: ComponentsMap) => !R.isEmpty(
        findWebshopComponent(componentsMap)
    );

export const getValidPageIds = (pageIdsToValidate: Array<string>, currentPageIds: Array<string>): Array<string> => {
    const result: Array<string> = pageIdsToValidate.filter((pageId) => (currentPageIds.indexOf(pageId) > -1));
    return pageIdsToValidate.length === result.length ? pageIdsToValidate : result;
};

/**
 * Cart component is shown only when -:
 * **   there is at least one webshop and
 * **   either or all of `show on all pages` or `checkout banner` is selected.
 * @param siteSettings
 * @param siteMap
 * @returns {boolean}
 */
export const checkIfCartComponentIsNotShown = (siteSettings: SiteSettings, siteMap: DataSite): boolean => {
    return !siteSettings ||
        (siteSettings.cartData && siteSettings.cartData.isCartWebshopPageOnly && !siteSettings.cartData.showMobileCart) ||
        !getValidPageIds(
            siteSettings.webshopPageIds || [],
            siteMap.getPageIdsNotMarkedAsDontPublish()
        ).length;
};

//Type is not specified since below function is used with and without mapped stylesheets.
export const getGSNormalTextColor = (globalStyles: any) => {
    return R.pipe(textNormalGlobalstyle, R.path([color]))(globalStyles);
};

export const getGlobalStyleButtonId = (defaultButtonStyle: ButtonStylesheet) => R.prop(id, defaultButtonStyle);

export const getDefaultButtonBGColor = (defaultButtonStyle: ButtonStylesheet) => {
    const defaultButtonBGColor = R.path([inactive, block, background, colorData, color], defaultButtonStyle),
        isBGColorWithOpacity1 = defaultButtonBGColor && defaultButtonBGColor[4] === 1;

    return isBGColorWithOpacity1 ? defaultButtonBGColor : null;
};

export const getWebshopLinkStyleForPreview = ({
    autoColorMode,
    globalStyles,
    selectedParentTheme,
}: {
    autoColorMode: boolean,
    globalStyles: Stylesheets,
    selectedParentTheme: ThemeBackgroundType,
    }) => {
    const linkStyleSheet = getFirstLinkStyle(globalStyles);
    const linkClassName = linkStyleSheet && getGlobalstyleClassNameFromStyle(linkStyleSheet);
    const StyleInstance = new Style({ props: {} });
    if (autoColorMode) {
        const
            themeColorsData = getThemeColorsFromStylesheet(globalStyles),
            color = colorMapper.toCss(
                themeColorsData[themeRulesBackgroundForLinkColor(themeColorsData).themeaccent[selectedParentTheme]]
            ),
            themeLinkStyleSheet = R.evolve({
                hover: hover => ({
                    ...hover, // $FlowFixMe: WBTGEN-16368
                    color: onecolor(color)
                        .mix(themeColorsData[getThemeRulesForBackground(selectedParentTheme, themeColorsData).background])
                        .cssa()
                }),
                inactive: inactive => ({ ...inactive, color }),
                visited: visited => ({ ...visited, color })
            })(linkStyleSheet),
            themeLinkStyleObject = linkClassName && LinkMapper(themeLinkStyleSheet),
            themeLinkStyleObjectWithAccentBase = R.map((classStyle) => ({
                ...classStyle,
                ...themeLinkAccentBase
            }), themeLinkStyleObject);
        return themeLinkStyleObjectWithAccentBase &&
            StyleInstance._buildStyles(themeLinkStyleObjectWithAccentBase);
    } else {
        const linkStyleObject = linkClassName && LinkMapper(linkStyleSheet);
        return linkStyleObject && StyleInstance._buildStyles(linkStyleObject);
    }
};

export const getColorsForWebshopPublishScript = ({
    autoColorMode,
    globalStyles,
    selectedParentTheme,
    component
}: {
    autoColorMode: boolean,
    globalStyles: Stylesheets,
    selectedParentTheme: ThemeBackgroundType,
    component: WebshopComponent | FeaturedProductsComponent
        }) => {
    if (autoColorMode) {
        const themeColorsData: ThemeColorDataType = getThemeColorsFromStylesheet(globalStyles);
        const {
                fontColor: fontColorThemeDefault,
                focusColor: focusColorThemeDefault,
                labelBgColor: labelBgColorThemeDefault,
                labelTextColor: labelTextColorThemeDefault,
                hoverColor: hoverColorThemeDefault
            } = getThemeRulesForWebshop(selectedParentTheme, themeColorsData),
            {
                fontColorTheme,
                focusColorTheme,
                labelBgColorTheme,
                labelTextColorTheme,
                promoRibbonBgColorTheme,
                promoRibbonTextColorTheme,
                hoverColorTheme
            } = component,
            hoverColor = themeColorsData[hoverColorTheme] || hoverColorThemeDefault,
            hoverColorWithOpacityInherited = R.assocPath([4], R.pathOr(0.2, [4], component.hoverColor), hoverColor);
        return {
            fontColor: themeColorsData[fontColorTheme] || fontColorThemeDefault,
            focusColor: themeColorsData[focusColorTheme] || focusColorThemeDefault,
            labelBgColor: themeColorsData[labelBgColorTheme] || labelBgColorThemeDefault,
            promoRibbonBgColor: themeColorsData[promoRibbonBgColorTheme] || labelBgColorThemeDefault,
            labelTextColor: themeColorsData[labelTextColorTheme] || labelTextColorThemeDefault,
            promoRibbonTextColor: themeColorsData[promoRibbonTextColorTheme] || labelTextColorThemeDefault,
            hoverColor: hoverColorWithOpacityInherited
        };
    } else {
        return {
            fontColor: component.fontColor,
            focusColor: component.focusColor,
            labelBgColor: component.labelBgColor || reducerDefaultData.labelBgColor,
            promoRibbonBgColor: component.promoRibbonBgColor || reducerDefaultData.promoRibbonBgColor,
            labelTextColor: component.labelTextColor || reducerDefaultData.labelTextColor,
            promoRibbonTextColor: component.promoRibbonTextColor || reducerDefaultData.promoRibbonTextColor,
            hoverColor: component.hoverColor || reducerDefaultData.hoverColor
        };
    }
};

export const doesShopComponentExists = (siteSettings: SiteSettings, siteMap: DataSite): boolean => {
    const shopPageIds = getValidPageIds(
        siteSettings.webshopPageIds || [],
        siteMap.getPageIdsNotMarkedAsDontPublish()
    );
    return Array.isArray(shopPageIds) && shopPageIds.length > 0;
};

export const getViewdetailsButtonStyleForPreview = (
    autoColorMode: boolean,
    buttonId: string,
    globalStyles: Stylesheets,
    selectedParentTheme: ThemeBackgroundType,
    buttonThemeSelected: ThemeButtonTypes,
): Record<string, any> => {
    let buttonStyle = null;
    let buttonClassname = '';
    let buttonStyleSheet: any = null;
    const StyleInstance = new Style({ props: {} });
    if (autoColorMode) {
        buttonStyleSheet = getFirstStylesheetByType(ButtonGlobalStyleKind)(globalStyles);
        const buttonStyleValues = R.mapObjIndexed(R.filter(v => !!v))(ButtonMapper(buttonStyleSheet));
        const themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        const themeStyle = getThemeStyleForButtonWithKnownTheme({ selectedParentTheme, buttonThemeSelected, themeColorsData });
        const hoverBackgroundColor = (
            // $FlowFixMe: WBTGEN-16368
            onecolor(themeStyle.backgroundColor)
                .mix(themeColorsData[getThemeRulesForBackground(selectedParentTheme, themeColorsData).background])
                .cssa()
        );
        const styleProp = R.mapObjIndexed((classStyle, selector) => ({
            ...classStyle,
            ...(R.pick([
                'color',
                'border',
                'backgroundImage',
                'backgroundColor',
            ], themeStyle)),
            backgroundColor: (['hover', 'active'].some(s => selector.includes(s)) ? hoverBackgroundColor : themeStyle.backgroundColor),
        }), buttonStyleValues);
        buttonStyle = StyleInstance._buildStyles(styleProp);
        const buttonGlobalStyleClassname = getGlobalstyleClassNameFromStyle(buttonStyleSheet);
        buttonClassname =
            `${buttonGlobalStyleClassname} ${selectedParentTheme} ${THEME_BUTTON_CLASS} ${buttonThemeSelected}`;
    } else {
        const buttonIdVal = getButtonStyleId(buttonId, globalStyles);
        buttonStyleSheet = getButtonStyleById(buttonIdVal)(globalStyles);
        buttonStyle = StyleInstance._buildStyles(ButtonMapper(buttonStyleSheet));
        buttonClassname = getGlobalstyleClassNameFromStyle(buttonStyleSheet);
    }
    return { buttonClassname, buttonStyle };
};

export const getFirstButtonStyleAndClassname = (
    autoColorMode: boolean,
    globalStyles: Stylesheets,
    selectedParentTheme: ThemeBackgroundType,
): Record<string, any> => {
    const buttonStyleSheet = getFirstButtonStyle(globalStyles);
    const buttonClassname = getGlobalstyleClassNameFromStyle(buttonStyleSheet);
    let buttonStyle = null;
    const StyleInstance = new Style({ props: {} });
    if (autoColorMode) {
        const buttonStyleValues = R.mapObjIndexed(R.filter(v => !!v))(ButtonMapper(buttonStyleSheet));
        const themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        const themeStyle = getThemeStyleForButtonWithKnownTheme({
            selectedParentTheme,
            buttonThemeSelected: BUTTON_THEME_PRIMARY,
            themeColorsData
        });
        const hoverBackgroundColor = (
            // $FlowFixMe: WBTGEN-16368
            onecolor(themeStyle.backgroundColor)
                .mix(themeColorsData[getThemeRulesForBackground(selectedParentTheme, themeColorsData).background])
                .cssa()
        );
        const styleProp = R.mapObjIndexed((classStyle, selector) => ({
            ...classStyle,
            ...(R.pick(['color', 'border', 'backgroundImage'], themeStyle)),
            backgroundColor: (['hover', 'active'].some(s => selector.includes(s)) ? hoverBackgroundColor : themeStyle.backgroundColor),
        }), buttonStyleValues);
        buttonStyle = StyleInstance._buildStyles(styleProp);
    } else {
        buttonStyle = StyleInstance._buildStyles(ButtonMapper(buttonStyleSheet));
    }
    return { buttonClassname, buttonStyle };
};

export const getDiscountDetailsStyle = (
    autoColorMode: boolean,
    globalStyles: Stylesheets,
    selectedParentTheme: ThemeBackgroundType,
): string => {
    const buttonStyleSheet = getFirstButtonStyle(globalStyles);
    let discountDetailsStyle = '';
    const StyleInstance = new Style({ props: {} });

    if (autoColorMode) {
        const themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        const themeStyle = getThemeStyleForButtonWithKnownTheme({
                selectedParentTheme,
                buttonThemeSelected: BUTTON_THEME_PRIMARY,
                themeColorsData
        });
        const lightenedPrimaryBackgroundColor = onecolor(themeStyle.backgroundColor).lightness(0.9).cssa();
        const discountDetailsStyleSheet = {
            '.discount-description-label': {
                backgroundColor: lightenedPrimaryBackgroundColor,
            },
        };
        discountDetailsStyle = StyleInstance._buildStyles(discountDetailsStyleSheet);
    } else {
        const buttonStyleValues = R.mapObjIndexed(R.filter(v => !!v))(ButtonMapper(buttonStyleSheet));
        const firstStyleRule = R.pipe(
            R.keys,          // Get the keys of the object
            R.head,          // Get the first key
            R.prop(R.__, buttonStyleValues) // Get the value of that key from the object
        )(buttonStyleValues);
        const backgroundColor = R.pathOr('#ffffff', ['backgroundColor'], firstStyleRule);
        const lightenedPrimaryBackgroundColor = onecolor(backgroundColor).lightness(0.9).cssa();
        const discountDetailsStyleSheet = {
            '.discount-description-label': {
                backgroundColor: lightenedPrimaryBackgroundColor,
            },
        };
        discountDetailsStyle = StyleInstance._buildStyles(discountDetailsStyleSheet);
    }
    return discountDetailsStyle;
};

export const getFirstButtonClassname = (
    autoColorMode: boolean,
    globalStyles: Stylesheets,
    selectedParentTheme: ThemeBackgroundType,
): string => {
    const buttonGlobalStylesheet = getFirstStylesheetByType(ButtonGlobalStyleKind)(globalStyles);
    const firstButtonClassName = getGlobalstyleClassNameFromStyle(buttonGlobalStylesheet);
    let buttonClassname = '';
    if (!autoColorMode) {
        const buttonStyle = getFirstButtonStyle(globalStyles);
        if (buttonStyle) {
            buttonClassname = getGlobalstyleClassNameFromStyle(buttonStyle);
        }
    }
    if (autoColorMode || !buttonClassname) {
        buttonClassname = firstButtonClassName;
    }
    if (autoColorMode) {
        buttonClassname =
            `${buttonClassname} ${selectedParentTheme} ${BUTTON_THEME_PRIMARY} ${THEME_BUTTON_CLASS}`;
    }
    return buttonClassname;
};

export const getWebshopLinkClassname = (
    globalStyles: Stylesheets,
): string => {
    let linkClassName = '';
    const linkStyleSheet = getFirstLinkStyle(globalStyles);
    if (linkStyleSheet) {
        linkClassName = linkStyleSheet && getGlobalstyleClassNameFromStyle(linkStyleSheet);
    }
    return linkClassName;
};

export const getViewdetailsButtonClassnames = (
    autoColorMode: boolean,
    buttonId: null | undefined | string,
    globalStyles: Stylesheets,
    selectedParentTheme: ThemeBackgroundType,
    buttonThemeSelected: ThemeButtonTypes,
): string => {
    let buttonClassname = '';
    let buttonStyleSheet: Stylesheet | null = null;
    if (autoColorMode) {
        buttonClassname = getFirstButtonClassname(autoColorMode, globalStyles, selectedParentTheme);
        buttonClassname = `${buttonClassname} ${selectedParentTheme} ${buttonThemeSelected} ${THEME_BUTTON_CLASS}`;
    } else {
        const buttonIdVal = getButtonStyleId(buttonId, globalStyles);
        buttonStyleSheet = getButtonStyleById(buttonIdVal)(globalStyles);
        // @ts-ignore
        buttonClassname = getGlobalstyleClassNameFromStyle(buttonStyleSheet);
        if (!buttonClassname) {
            const buttonStyle = getFirstButtonStyle(globalStyles);
            buttonClassname = getGlobalstyleClassNameFromStyle(buttonStyle);
        }
    }
    return buttonClassname;
};

export const getWebshopPublishBaseUrl = (domainName: string, config?: Record<string, any>): string => {
    let result = '';
    const appConfig = AppConfig(config || getAppConfig());
    const webshopAppUrl = appConfig.get('oneWeb.webshop.appUrl');
    const useAppUrlInPublishing = appConfig.get('oneWeb.webshop.useAppUrlInPublishing');
    if (useAppUrlInPublishing) {
        result = getMergedWebshopAppShopfrontBaseUrl(webshopAppUrl, domainName);
    } else {
        result = `/____webshop/v1/${domainName}`;
    }
    return result;
};
