// @ts-nocheck
import { toCss as colorMapper } from '../../../../../mappers/color';
import { toCss as shadowMapper } from '../../../../../mappers/shadow';
import type { Element, Stylesheet } from '../../../flowTypes';

export default (defaultGlobalStyles: Array<Stylesheet>, styles: Array<Element>): Array<Element> => {
    return styles.map(style => {
        const defaultGlobalStyle = defaultGlobalStyles
            .find(global => global.name === style.globalName);
        const newStyle = {
            ...style
        };

        // colors are stored in array so when we compare two arrays with identical values
        // will return wrong result, so we need to convert it to rgba string format
        if (newStyle.color) {

            newStyle.color = colorMapper(newStyle.color);
        }

        if (newStyle.highlight) {

            newStyle.highlight = colorMapper(newStyle.highlight);
        }

        if (newStyle.shadow) {

            newStyle.shadow = shadowMapper(newStyle.shadow);
        }

        if (defaultGlobalStyle) {
            newStyle.globalName = defaultGlobalStyle.name;
            newStyle.ref = defaultGlobalStyle.ref;
        }

        return newStyle;
    });
};
