import CALL_API from '../../../../redux/middleware/api/CALL_API';

export const
    DOMAIN_IS_ONEHOPS_CLIENT_REQUEST = "DOMAIN_IS_ONEHOPS_CLIENT_REQUEST",
    DOMAIN_IS_ONEHOPS_CLIENT_SUCCESS = "DOMAIN_IS_ONEHOPS_CLIENT_SUCCESS",
    DOMAIN_IS_ONEHOPS_CLIENT_FAILURE = "DOMAIN_IS_ONEHOPS_CLIENT_FAILURE",

    domainIsOneHopClient = () => ({
        [CALL_API]: {
            types: [
                DOMAIN_IS_ONEHOPS_CLIENT_REQUEST,
                DOMAIN_IS_ONEHOPS_CLIENT_SUCCESS,
                DOMAIN_IS_ONEHOPS_CLIENT_FAILURE,
            ],
            endpoint: 'isOneHopClient',
        }
    });
