import React from 'react';
import { connect } from "react-redux";
import { Msg, injectIntl, Intl } from "../../../../view/intl/index";
import Button from '../../../../view/common/Button/Button';
import styles from './ShutterstockDownloads.css';
import { hideShutterstockPremiumDownloadsMsgAction } from "../actions";
import { hideUnsplashPremiumDownloadsMsgAction } from "../../unsplash/actions";
import { isDemoSubscriptionType } from '../../../App/epics/subscriptionData/isDemoSubscriptionType';
import { demoBuyButtonClickAction } from '../../../../../demo/modules/actions';
import { DemoBuyBtn } from '../../../../../demo/modules/buyButton/DemoBuyBtn';
import { isNormalSubscription, isPremiumSubscription } from "../../../App/epics/subscriptionData/utils";
import type { ShutterstockKindT } from "../types";
import type { AppState, SubscriptionData } from '../../../../redux/modules/flowTypes';
import { getPremiumPackageNameFromMetadata } from '../../../ComponentTierManager/utils';
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../../ComponentTierManager/actionCreators";
import OpenUpgradePlanDialog from "../../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { ThirdPartyImageKind } from '../../unsplash/components/constants';
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import subscriptionDataVat from "../../../App/epics/subscriptionData/valueActionType";
import { ShutterstockKind } from '../constants';
import { upgradeDialogOpenedAction } from '../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_SHUTTERSTOCK_IMAGES } from '../../../TopBar/view/Upgrade/tracking/contants';
import { UpgradePlansDialogId } from '../../../TopBar/view/dialogIds';

type Props = {
    kind: ShutterstockKindT,
    subscriptionType: string;
    subscriptionData: SubscriptionData;
    numberOfDownload: number;
    quota: number;
    showUpgradeMsg: boolean;
    dispatch: Function;
    intl: Intl;
};

const TotalImagesDownloaded = ({ numberOfDownload, maxDownload }) => (
    <Msg k="shutterstock.freeDownloaded" params={{ numberOfDownload, maxDownload }}>
        {`{numberOfDownload} of {maxDownload} free images downloaded`}
    </Msg>
);

class ShutterstockDownloadsCmp extends React.Component<Props> {
    onOkHandler = () => {
        if (this.props.kind === ThirdPartyImageKind.UNSPLASH) {
            return this.props.dispatch(hideUnsplashPremiumDownloadsMsgAction());
        }
        return this.props.dispatch(hideShutterstockPremiumDownloadsMsgAction(this.props.kind));
    }

    onUpgradeHandler = () => {
        const { subscriptionData } = this.props;
        this.props.dispatch(OpenUpgradePlanDialog(subscriptionData, "fcFreeOneComTab:Upgrade"));
        this.props.dispatch(upgradeDialogOpenedAction({
            feature: FEATURE_SHUTTERSTOCK_IMAGES,
            dialogId: UpgradePlansDialogId
        }));
    }

    onDemoBuyNowClick = () => {
        this.props.dispatch(calculateUpgradeTierBeforeSeamlessUpgrade("trial:buynow", FEATURE_SHUTTERSTOCK_IMAGES, "", false));
        this.props.dispatch(demoBuyButtonClickAction());
    }

    render() {
        const { kind, subscriptionType, numberOfDownload, quota, showUpgradeMsg, subscriptionData, intl } = this.props,
            { metadata: subscriptionMetadata } = subscriptionData,
            premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata),
            isUnsplashTabIsActive = kind === ThirdPartyImageKind.UNSPLASH,
            isFreeOneComTabActive = kind === ShutterstockKind.FREE,
            premiumPackageName = premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}');

        let header: React.JSX.Element | null = null;
        if (isPremiumSubscription(subscriptionType)) {
            header = showUpgradeMsg ? (
                <div className={styles.subscriptionHeaderWrapper}>
                    <div className={styles.upgradedMsg}>
                        <Msg
                            k="sstock.upgradedMsg"
                            params={{ packageName: premiumPackageName }}
                        >{`
                            Congrats! With {packageName} you get unlimited downloads of free images.
                        `}</Msg>
                    </div>
                    <div className={styles.upgradedOkBtnWrapper}>
                        <Button
                            onClick={this.onOkHandler}
                            className={styles.subscriptionButton}
                        >
                            <Msg k="common.ok">OK</Msg>
                        </Button>
                    </div>
                </div>
            ) : null;
        }

        if (isNormalSubscription(subscriptionType)) {
            if (isUnsplashTabIsActive) {
                // eslint-disable-next-line
                const unsplashSubMessage = 'msg: unsplash.normalUserInfoToUpgrade_sub {Upgrade to get access to thousands of free, high quality images.}';
                header = (
                    <div className={styles.subscriptionHeaderWrapper}>
                        <div className={styles.fcUnsplashSubscriptionMsg}>
                            <h3>
                                <Msg k="unsplash.normalUserInfoToUpgrade" params={{ premiumPackageName }}>
                                    Unsplash images are only available to Pro users
                                </Msg>
                            </h3>
                            <p className={styles.fcUnsplashUpgradeMsg}>
                                {intl.msgJoint(unsplashSubMessage)}
                            </p>
                        </div>
                    </div>
                );
            } else if (numberOfDownload === quota) {
                header = (
                    <div className={styles.subscriptionHeaderWrapper}>
                        <div className={styles.maxDownloadMsg}>
                            <h3>
                                <Msg k="shutterstock.upgradeToGetUnliDownloads">
                                    Upgrade to get unlimited downloads
                                </Msg>
                            </h3>
                            <p className={styles.totalDownloadMsg}>
                                <TotalImagesDownloaded
                                    numberOfDownload={numberOfDownload}
                                    maxDownload={quota}
                                />
                            </p>
                        </div>
                        <div className={styles.upgradedOkBtnWrapper}>
                            <Button
                                className={styles.subscriptionButton}
                                onClick={this.onUpgradeHandler}
                            >
                                <Msg k="common.upgrade">Upgrade</Msg>
                            </Button>
                        </div>
                    </div>
                );
            } else if (!isFreeOneComTabActive) {
                const upgradeLink = (
                    <a
                        onClick={this.onUpgradeHandler}
                        className={styles.upgradeLink}
                    ><Msg k="shutterstock.upgradeToUnliDownloads.link">Upgrade</Msg></a>
                );
                header = (
                    <div className={styles.subscriptionHeaderWrapper}>
                        <div className={styles.getFreeMsg}>
                            <h3>
                                <Msg k="shutterstock.getFreeImages" params={{ quota }}>
                                    {`Get {quota} free images from One.com`}
                                </Msg>
                            </h3>
                            <p className={styles.totalDownloadMsg}>
                                <TotalImagesDownloaded
                                    numberOfDownload={numberOfDownload}
                                    maxDownload={quota}
                                />
                                &nbsp;-&nbsp;
                                <Msg k="shutterstock.upgradeToUnliDownloads" params={{ upgradeLink }}>
                                    {`{upgradeLink} to get unlimited downloads`}
                                </Msg>
                            </p>
                        </div>
                    </div>
                );
            }
        }

        // @ts-ignore
        if (isDemoSubscriptionType(subscriptionType) && !(isUnsplashTabIsActive || isFreeOneComTabActive)) {
            header = (
                <div className={styles.subscriptionHeaderWrapper}>
                    <div className={styles.upgradedMsg}>
                        <Msg k="demo.freeOneComImages.message">
                            Purchase a plan to get access to free images.
                        </Msg>
                    </div>
                    <div className={styles.demoButNowContainer}>
                        <DemoBuyBtn
                            className={styles.subscriptionButton}
                            label="msg: common.buyNow {Buy now}"
                        />
                    </div>
                </div>
            );
        }

        return header;
    }
}

const subscriptionDataSelector = makeEpicStateSelector(subscriptionDataVat);

const mapStateToProps = (appState: AppState): { subscriptionData: SubscriptionData } => {
    const subscriptionData = subscriptionDataSelector(appState);
    return ({
        subscriptionData
    });
};

const ShutterstockDownloads = connect(mapStateToProps)(injectIntl(ShutterstockDownloadsCmp));
export {
    ShutterstockDownloads
};
