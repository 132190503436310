import React from 'react';
import cx from 'classnames';

import { Msg, useIntl } from '../../../../../../../view/intl/index';
import {
    AI_TEXT_REPLACE_BTN_CLICKED,
    AI_TEXT_COPY_BTN_CLICKED
} from "../../../../epics/AITextEpic/actionTypes";

import styles from '../styles.css';
import { ResultLoader } from './ResultLoader';

export const ResultCard = ({
    dispatch,
    queryResult,
    isLatestQuery,
    isQueryInProgress,
    index,
    insertedText,
    copiedText,
    hasWrappedElements
}) => {
    const intl = useIntl();
    const [isInClipboard, setIsInClipboard] = React.useState(false);
    const [isInserted, setIsInserted] = React.useState(false);
    const isLatestQueryInStreaming = isLatestQuery && isQueryInProgress;
    const onQueryResultMouseOver = () => {
        if (copiedText === queryResult) {
            setIsInClipboard(true);
        } else {
            setIsInClipboard(false);
        }

        if (insertedText === queryResult) {
            setIsInserted(true);
        } else {
            setIsInserted(false);
        }
    };
    const onQueryResultMouseClick = () => {
        if (!navigator.clipboard) {
            return;
        }
        let textToBeCopied = queryResult;
        if (queryResult && (typeof queryResult === 'string')) {
            textToBeCopied = textToBeCopied.replace(/<br>/g, '\n\n');
        }
        navigator.clipboard.writeText(textToBeCopied);
        setIsInClipboard(true);
        dispatch({ type: AI_TEXT_COPY_BTN_CLICKED, payload: queryResult });
    };
    return (
        <div
            key={index}
            className={cx(styles.queryResultContainer, {
                [styles.isStreaming]: isLatestQueryInStreaming,
                [styles.isLatestQuery]: isLatestQuery,
            })}
            onMouseOver={onQueryResultMouseOver}
        >
            <div className={styles.versionPanel}>
                <Msg k="component.text.createAIText.dialog.optionLabel" params={{ count: index + 1 }}>
                    {`Option {count}`}
                </Msg>
            </div>
            <div className={styles.queryResultText}>
                <span dangerouslySetInnerHTML={{ __html: queryResult }} />
                {isLatestQueryInStreaming && <ResultLoader />}
            </div>
            <div className={cx(styles.buttonsContainer, {
                [styles.buttonsContainerHidden]: isLatestQueryInStreaming,
            })}
            >
                <button
                    type="button"
                    className={styles.resultActionButton}
                    onClick={onQueryResultMouseClick}
                >
                    <span className={styles.copyIcon} />
                    <span>
                        {isInClipboard ?
                            <Msg k="common.copiedLabel">Copied</Msg> :
                            <Msg k="common.copyTextLabel">Copy text</Msg>}
                    </span>
                </button>
                <button
                    type="button"
                    className={styles.resultActionButton}
                    onClick={() => {
                        setIsInserted(true);
                        dispatch({ type: AI_TEXT_REPLACE_BTN_CLICKED, payload: { content: queryResult } });
                    }}
                    disabled={hasWrappedElements}
                    data-title={
                        hasWrappedElements ?
                            intl.msgJoint("msg: component.text.writingAssistant.useText.disable {Cannot insert the text because another component is wrapped inside the text field. Copy text instead.}") :
                            null
                    }
                    data-title-position="top"
                    data-title-class={styles.useTextTooltip}
                >
                    <span className={styles.insertIcon} />
                    <span>
                        {isInserted ?
                            <Msg k="common.insertedLabel">Inserted</Msg> :
                            <Msg k="common.useTextLabel">Use text</Msg>}
                    </span>
                </button>
            </div>
        </div>
    );
};
