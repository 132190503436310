import React from "react";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import ColorProperty from "../../../../presentational/ColorProperty/index";
import actionTypes from "../../reducer/actionTypes";
import injectIntl from "../../../../../view/intl/injectIntl";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { PropertiesPanelStyleViewProps } from "../../flowTypes";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import ComboBox from "../../../../../view/common/Combobox/IntlCombobox";
import { labelPositionOptions } from "../../options";
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";
import { THEME_COLOR_PICKER } from "../../../../ColorPicker/constants";
import { getThemeRulesForWebshop } from "../../../../ThemeGlobalData/themeRules";
import Label from "../../../../../view/common/Label";

const id: any = pagesIds.PROMO,
    title = pagesTitles[pagesIds.PROMO],
    view = injectIntl((props: PropertiesPanelStyleViewProps) => {
        const {
                dispatch,
                selectedComponent,
                globalStyles,
                themeSettingsData: { autoColorMode }
            } = props,
            themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        return (
            <Page>
                <VerticalSpacer />
                <label>
                    <Label>{"msg: component.promotion.promotionLabel {Add your first ribbon in your shop inventory.}"}</Label>
                </label>
                <VerticalSpacer />
                <label>
                    <Label>{"msg: component.promotion.promotionProductRibbonLabel {Product ribbon}"}</Label>
                </label>
                <VerticalSpacer />
                {autoColorMode
                    ? [
                        <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            themeColor={selectedComponent.labelBgColorTheme}
                            defaultThemeColor={
                                getThemeRulesForWebshop(props.selectedComponentProps.selectedParentTheme, themeColorsData)
                                    .labelBgColorName
                            }
                            label="msg: component.webshop.labelColor {Label colour}"
                            onChangeAction={actionTypes.WEBSHOP_LABEL_BG_COLOR_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />,
                        <VerticalSpacer />,
                        <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            themeColor={selectedComponent.labelTextColorTheme}
                            defaultThemeColor={
                                getThemeRulesForWebshop(props.selectedComponentProps.selectedParentTheme, themeColorsData)
                                    .labelTextColorName
                            }
                            label="msg: component.webshop.labelTextColor {Label text colour}"
                            onChangeAction={actionTypes.WEBSHOP_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                    ]
                    : [
                        <ColorProperty
                            onRemoveAction=""
                            label="msg: component.webshop.labelColor {Label colour}"
                            color={selectedComponent.labelBgColor}
                            onChangeAction={actionTypes.WEBSHOP_LABEL_BG_COLOR_CHANGED}
                            dispatch={dispatch}
                        />,
                        <VerticalSpacer />,
                        <ColorProperty
                            onRemoveAction=""
                            label="msg: component.webshop.labelTextColor {Label text colour}"
                            color={selectedComponent.labelTextColor}
                            onChangeAction={actionTypes.WEBSHOP_LABEL_TEXT_COLOR_CHANGED}
                            dispatch={dispatch}
                        />
                    ]}
                <VerticalSpacer />
                <PropertyContainer label="msg: component.webshop.labelPosition {Label position}">
                    <ComboBox
                        searchable={false}
                        options={labelPositionOptions}
                        value={selectedComponent.labelPosition}
                        onChange={({ value }) =>
                            dispatch({
                                type: actionTypes.WEBSHOP_LABEL_POSITION_CHANGED,
                                payload: value
                            })}
                    />
                </PropertyContainer>
                <React.Fragment>
                    <VerticalSpacer />
                    <label>
                        <Label>{"msg: component.promotion.promotionDiscountRibbonLabel {Discount ribbon}"}</Label>
                    </label>
                    <VerticalSpacer />
                    {autoColorMode
                        ? [
                            <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.promoRibbonBgColorTheme}
                                defaultThemeColor={
                                    getThemeRulesForWebshop(props.selectedComponentProps.selectedParentTheme, themeColorsData)
                                        .labelBgColorName
                                }
                                label="msg: component.webshop.labelColor {Label colour}"
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />,
                            <VerticalSpacer />,
                            <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.promoRibbonTextColorTheme}
                                defaultThemeColor={
                                    getThemeRulesForWebshop(props.selectedComponentProps.selectedParentTheme, themeColorsData)
                                        .labelTextColorName
                                }
                                label="msg: component.webshop.labelTextColor {Label text colour}"
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                        ]
                        : [
                            <ColorProperty
                                onRemoveAction=""
                                label="msg: component.webshop.labelColor {Label colour}"
                                color={selectedComponent.promoRibbonBgColor}
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED}
                                dispatch={dispatch}
                            />,
                            <VerticalSpacer />,
                            <ColorProperty
                                onRemoveAction=""
                                label="msg: component.webshop.labelTextColor {Label text colour}"
                                color={selectedComponent.promoRibbonTextColor}
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED}
                                dispatch={dispatch}
                            />
                        ]}
                </React.Fragment>
            </Page>
        );
    });

export { id, title, view };
