/* eslint-disable max-len */
import { pathOr } from 'ramda';
import urlParseTemplate from 'urlparse-template';

import { PremiumTierComponents, EcommerceTierComponents, StandardTierComponents } from "../../view/oneweb/registry/ComponentTierTypes";
import { ComponentTierTypes } from "./componentTierTypes";
import {
    isTrialSubscription,
    isNormalSubscription,
    isPremiumSubscription,
    isEcommerceSubscription,
    isBasicSubscription,
    isPagesOrSectionLinksLimitExceededGivenSubscriptionType
} from "../App/epics/subscriptionData/utils";
import type { AnyComponentKind, AnyFeatureKind } from "../../redux/modules/children/workspace/flowTypes";
import type { ComponentTierTypeT } from "./flowTypes";
import type { SubscriptionTypeT } from "../App/epics/subscriptionData/flowTypes";
// import { DataSite } from "../../../dal/model/index";
import { SubscriptionType } from "../../../../server/shared/subscriptionStatus/constants";
import type { SubscriptionMetadata } from '../../redux/modules/flowTypes';
import { getAppConfig } from "../App/epics/appConfig/appConfig";
import { readWsbCustomerProfileCookiePayload } from '../../utils/wsbCustomerProfile/wsbCustomerProfileUtils';
import AppConfig from "../../utils/AppConfig";
import { WsbFeature } from "../../../../server/shared/WsbFeature";
import getSubscriptionFeatureManager from "../../getSubscriptionFeatureManager";
import type { WsbFeatureT } from "../../../../server/shared/WsbFeature";
import { GoogleReviewsKind } from "../oneweb/GoogleReviews/kind";
import FeatureTypes from '../../view/oneweb/registry/FeatureTypes';
import ComponentTypes from '../../view/oneweb/registry/ComponentTypes';
import { PartnerName } from '../../../../server/shared/partners/PartnerName';
import { isGenericWidget, isWidgetAllowedForAllSubscriptions } from '../oneweb/Widgets/utils';

export const isBasicTier = (tier: ComponentTierTypeT) => {
    return tier === ComponentTierTypes.BASIC_TIER_COMPONENT;
};

export const isStandardTier = (tier: ComponentTierTypeT) => {
    return tier === ComponentTierTypes.STANDARD_TIER_COMPONENT;
};

export const isPremiumTier = (tier: ComponentTierTypeT) => {
    return tier === ComponentTierTypes.PREMIUM_TIER_COMPONENT;
};

export const isEcommerceTier = (tier: ComponentTierTypeT) => {
    return tier === ComponentTierTypes.ECOMMERCE_TIER_COMPONENT;
};

export const getComponentTierDataFromComponentKind = (kind: (AnyComponentKind | AnyFeatureKind)) => {
    let componentTierData;

    if (EcommerceTierComponents[kind]) {
        componentTierData = {
            ...EcommerceTierComponents[kind],
            componentTierType: ComponentTierTypes.ECOMMERCE_TIER_COMPONENT
        };
    } else if (PremiumTierComponents[kind]) {
        componentTierData = {
            ...PremiumTierComponents[kind],
            componentTierType: ComponentTierTypes.PREMIUM_TIER_COMPONENT
        };
    } else if (StandardTierComponents[kind]) {
        componentTierData = {
            ...StandardTierComponents[kind],
            componentTierType: ComponentTierTypes.STANDARD_TIER_COMPONENT
        };
    } else {
        componentTierData = {
            componentTierType: ComponentTierTypes.BASIC_TIER_COMPONENT
        };
    }

    return componentTierData;
};

export const checkComponentSubscriptionCompatibility = (componentTierType: ComponentTierTypeT, userSubscriptionType: SubscriptionTypeT) => {
    let componentTierRanking,
        userSubscriptionRanking;

    if (isEcommerceTier(componentTierType)) {
        componentTierRanking = 6;
    } else if (isPremiumTier(componentTierType)) {
        componentTierRanking = 4;
    } else if (isStandardTier(componentTierType)) {
        componentTierRanking = 2;
    } else {
        // TRIAL or NORMAL
        componentTierRanking = 1;
    }

    // WSB Trial users should be treated as user with PREMIUM subscription
    if (isEcommerceSubscription(userSubscriptionType)) {
        userSubscriptionRanking = 6;
    } else if (isPremiumSubscription(userSubscriptionType)) {
        userSubscriptionRanking = 4;
    } else if (isNormalSubscription(userSubscriptionType)) {
        userSubscriptionRanking = 2;
    } else {
        userSubscriptionRanking = 1;
    }

    return (userSubscriptionRanking >= componentTierRanking);
};

export const checkSubscriptionCompatibilityFromKind = (
    kind: (AnyComponentKind | AnyFeatureKind),
    userSubscriptionType?: SubscriptionTypeT,
) => {
    if (kind === GoogleReviewsKind) {
        return getSubscriptionFeatureManager().isGoogleReviewsAllowed();
    } else if (kind === FeatureTypes.BUSINESS_LISTINGS) {
        return getSubscriptionFeatureManager().isGoogleMyBusinessAllowed();
    } else if (isGenericWidget(kind) && isWidgetAllowedForAllSubscriptions(kind) === false) {
        return getSubscriptionFeatureManager().isGenericWidgetAllowed();
    } else {
        const componentTierData = getComponentTierDataFromComponentKind(kind);
        return checkComponentSubscriptionCompatibility(componentTierData.componentTierType, userSubscriptionType);
    }
};

export const checkEcommerceSubscriptionCompatibilityFromKind =
    // eslint-disable-next-line no-unused-vars
    (kind: (AnyComponentKind | AnyFeatureKind), userSubscriptionType: SubscriptionTypeT/*, siteData: DataSite*/) => {
        const componentTierData = getComponentTierDataFromComponentKind(kind);
        return checkComponentSubscriptionCompatibility(componentTierData.componentTierType, userSubscriptionType);
    };

export const getEventValueFromComponentKind = (kind: (AnyComponentKind | AnyFeatureKind)) => {
    let eventValue;
    if (EcommerceTierComponents[kind]) {
        eventValue = EcommerceTierComponents[kind].gaEventValue;
    } else if (PremiumTierComponents[kind]) {
        eventValue = PremiumTierComponents[kind].gaEventValue;
    }
    return eventValue;
};

export const isShopRelatedComponent = (kind: string) => {
    if ((kind === ComponentTypes.WEBSHOP) ||
        (kind === ComponentTypes.PRODUCT_WIDGET) ||
        (kind === ComponentTypes.FEATURED_PRODUCTS_KIND)) {
        return true;
    }
    return false;
};

export const getSubscriptionTypeForSeamlessUpgrade =
    (
        componentsUsed: Array<AnyComponentKind | AnyFeatureKind>,
        pagesCount: number, // TODO: Rename it to `publicPagesCount`
        sectionLinksCount: number,
        currentSubscriptionType: string,
        shopPageIdsCount?: number
    ) => {
        let
            ecommerceTierComponents = Object.keys(EcommerceTierComponents);
        ecommerceTierComponents = ecommerceTierComponents.filter(feature => !isShopRelatedComponent(feature));
        const
            isEcommerceTierComponentPresent = componentsUsed.some(componentUsed => ecommerceTierComponents.includes(componentUsed));
        if (isEcommerceTierComponentPresent) {
            return SubscriptionType.ECOMMERCE;
        }

        const
            premiumTierComponents = Object.keys(PremiumTierComponents),
            isPremiumTierComponentPresent = componentsUsed.some(componentUsed => premiumTierComponents.includes(componentUsed));

        const
            standardTierComponents = Object.keys(StandardTierComponents),
            isStandardTierComponentsPresent = componentsUsed.some(componentUsed => standardTierComponents.includes(componentUsed));

        if (currentSubscriptionType === SubscriptionType.NORMAL || isBasicSubscription(currentSubscriptionType)) {
            if (isPremiumTierComponentPresent || isPagesOrSectionLinksLimitExceededGivenSubscriptionType(pagesCount, sectionLinksCount, SubscriptionType.NORMAL)) {
                return SubscriptionType.PREMIUM;
            }
            if (isBasicSubscription(currentSubscriptionType)) {
                if (isStandardTierComponentsPresent || isPagesOrSectionLinksLimitExceededGivenSubscriptionType(pagesCount, sectionLinksCount, SubscriptionType.BASIC)) {
                    return SubscriptionType.NORMAL;
                }
            }
        }
        if (isTrialSubscription(currentSubscriptionType)) {
            // TODO: There can/should be checks for components from different tiers across the pages in Trial as well.
            if (
                shopPageIdsCount || // WBTGEN-33600
                isEcommerceTierComponentPresent // NOTE: This check is already done above, but keeping it here for clarity and potential future changes.
            ) {
                // TODO: There can/should be checks for: (across the pages)
                //           * Product widgets
                //           * Featured Products (It is currently not available on Trial though)
                return SubscriptionType.ECOMMERCE;
            } else if (
                pagesCount >= 2 || // WBTGEN-33600
                isPremiumTierComponentPresent ||
                isPagesOrSectionLinksLimitExceededGivenSubscriptionType(pagesCount, sectionLinksCount, SubscriptionType.NORMAL)
            ) {
                return SubscriptionType.PREMIUM;
            } else if (isStandardTierComponentsPresent || isPagesOrSectionLinksLimitExceededGivenSubscriptionType(pagesCount, sectionLinksCount, SubscriptionType.BASIC)) {
                return SubscriptionType.NORMAL;
            } else {
                return SubscriptionType.BASIC;
            }
        }

        return currentSubscriptionType;
    };

export const getPackageNameFromMetadata = (subscriptionType: SubscriptionTypeT, metadata: null | undefined | SubscriptionMetadata): string =>
    pathOr(
        undefined,
        ['subscriptionLabels', subscriptionType],
        metadata,
    );

export const getPremiumPackageNameFromMetadata = (subscriptionMetadata: null | undefined | SubscriptionMetadata): string =>
    getPackageNameFromMetadata(SubscriptionType.PREMIUM, subscriptionMetadata);

export const getEcommercePackageNameFromMetadata = (subscriptionMetadata: null | undefined | SubscriptionMetadata): string | typeof undefined => {
    return getPackageNameFromMetadata(SubscriptionType.ECOMMERCE, subscriptionMetadata);
};

const getAllowedSubscriptionTypesByConfig = (): SubscriptionTypeT[] =>
    pathOr([], ['oneWeb', 'subscription', 'allowedTypes'], getAppConfig());

const ComponentTierSubscriptionTypeMap = {
    [ComponentTierTypes.BASIC_TIER_COMPONENT]: SubscriptionType.BASIC,
    [ComponentTierTypes.STANDARD_TIER_COMPONENT]: SubscriptionType.NORMAL,
    [ComponentTierTypes.PREMIUM_TIER_COMPONENT]: SubscriptionType.PREMIUM,
    [ComponentTierTypes.ECOMMERCE_TIER_COMPONENT]: SubscriptionType.ECOMMERCE
};

export const getPartnerName = () => {
    const appConfig = getAppConfig();
    const partnerName = pathOr(PartnerName.ONECOM, ['partnerName'], appConfig);
    return partnerName;
};

export const isPartnerOneCom = () => {
    return getPartnerName() === PartnerName.ONECOM;
};

export const isPartnerHostnet = () => {
    return getPartnerName() === PartnerName.HOSTNET;
};

export const isPartnerCheckDomain = () => {
    return getPartnerName() === PartnerName.CHECKDOMAIN;
};

const ComponentTierSubscriptionTypeRankingMap = {
    [SubscriptionType.BASIC]: 10,
    [SubscriptionType.NORMAL]: 20,
    [SubscriptionType.PREMIUM]: 30,
    [SubscriptionType.ECOMMERCE]: 40
};

const getHighestRankFromSubscriptions = (subs: Array<string>) => {
    let result = 0;
    subs.forEach((sub: string) => {
        if (result < ComponentTierSubscriptionTypeRankingMap[sub]) {
            result = ComponentTierSubscriptionTypeRankingMap[sub];
        }
    });
    return result;
};

export const isComponentOrFeatureTierDisabledByConfiguration = (kind: (AnyComponentKind | AnyFeatureKind)) => {
    const subs = getAllowedSubscriptionTypesByConfig(),
        highestSubRank = getHighestRankFromSubscriptions(subs),
        currentSubRank = ComponentTierSubscriptionTypeRankingMap[
            ComponentTierSubscriptionTypeMap[
                getComponentTierDataFromComponentKind(kind).componentTierType
            ]
        ];
    return currentSubRank > highestSubRank;
};

export const isEcommerceSubscriptionTypeEnabled = () => (getAllowedSubscriptionTypesByConfig().includes(SubscriptionType.ECOMMERCE) || 
getAllowedSubscriptionTypesByConfig().includes(SubscriptionType.ECOMMERCE_DIFM));

export const isShopInAllTiersEnabled = () =>
    pathOr(false, ['oneWeb', 'webshop', 'features', 'enableShopInAllTier'], getAppConfig());

export const isSeamlessUpgradeDisabledByConfig = () =>
    pathOr(false, ['oneWeb', 'subscription', 'seamlessUpgrade', 'disabled'], getAppConfig());

export const isBackupRestoreAddOnDisabledByConfig = () =>
    pathOr(false, ['oneWeb', 'subscription', 'backupRestoreAddon', 'disabled'], getAppConfig());

export const getExternalPlansLink = () => {
    const configuredUrl = pathOr(undefined, ['oneWeb', 'subscription', 'externalPlansUrl'], getAppConfig());
    if (configuredUrl) {
        const customerProfileData = readWsbCustomerProfileCookiePayload(),
            { profileId } = customerProfileData || {};
        if (!profileId) {
            throw new Error('Unable to retrieve profile id for external plans link');
        }

        return urlParseTemplate(configuredUrl)({ profileId }).href;
    } else {
        return null;
    }
};

const WsbFeatureComponentTierModuleItemMap = {
    [WsbFeature.FacebookChat]: FeatureTypes.FACEBOOK_CHAT,
    [WsbFeature.OnlineShop]: ComponentTypes.WEBSHOP,
    [WsbFeature.GoogleMyBusiness]: FeatureTypes.BUSINESS_LISTINGS,
    [WsbFeature.Shutterstock]: null,
    [WsbFeature.ShutterstockFree]: null,
    [WsbFeature.Tracking]: FeatureTypes.TRACKING_TAB,
    [WsbFeature.GoogleAds]: null,
    [WsbFeature.Dashboard]: null,
    [WsbFeature.Unsplash]: null
};

/**
 * This method helps in toggling features for partners.
 */
export const isWsbFeatureEnabled = (wsbFeature: WsbFeatureT) => {
    const appConfig = AppConfig(getAppConfig()),
        isFeatureEnabled = appConfig.getOptional(`oneWeb.${wsbFeature}.enabled`, true),
        isFeatureDisabled = appConfig.getOptional(`oneWeb.${wsbFeature}.disabled`, false),
        featureItemMap = WsbFeatureComponentTierModuleItemMap[wsbFeature],
        isComponentOrFeatureTierDisabledByConfig = isComponentOrFeatureTierDisabledByConfiguration(
            WsbFeatureComponentTierModuleItemMap[wsbFeature]
        );
    let retVal = false;
    if (isFeatureEnabled &&
        !isFeatureDisabled &&
        !(featureItemMap &&
            isComponentOrFeatureTierDisabledByConfig)
    ) {
        retVal = true;
    }
    return retVal;
};

export const isGoogleAnalyticsAllowedForNonBNEUsers = (subscriptionType: SubscriptionTypeT, trackingData) => {
    /**
     * This function determines if a non B+E user can continue using GA + Pixel
     * because of bug in the application flow.
     */

    return (
        !isEcommerceSubscription(subscriptionType)
        && trackingData
        && trackingData.gaMeasurementId
        && trackingData.gaMeasurementId.trim().length > 0
    );
};

export const isFacebookPixelAllowedForNonBNEUsers = (subscriptionType: SubscriptionTypeT, trackingData) => {
    /**
     * This function determines if a non B+E user can continue using GA + Pixel
     * because of bug in the application flow.
     */

    return (
        !isEcommerceSubscription(subscriptionType)
        && trackingData
        && trackingData.pixelId
        && trackingData.pixelId.trim().length > 0
    );
};
