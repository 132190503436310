import React from "react";
import { ColorTheme } from "./Components/ColorTheme";
import { Intl } from "../../../view/intl/index";

type AutoColorModeOffProps = {
    intl: Intl;
    dispatch: Dispatch;
};
export const AutoColorModeOff = (props: AutoColorModeOffProps) => {
    const { intl, dispatch } = props;
    return (
        <div
            style={{
                marginLeft: "25px"
            }}
        >
            <ColorTheme currentAutoColorMode={false} intl={intl} dispatch={dispatch} />
        </div>
    );
};
