import kind from "./kind";
import view from './view/index';
import workspace from './view/workspace';
import reducer from './reducer/index';
import calcRenderProps from './calcRenderProps';
import previewConfig from './previewConfig';
import policiesEpic from '../epic/index';
import { formatMessage } from '../../../../view/intl/CurrentLocale';
import stylesheetsEpic from '../../../Workspace/epics/stylesheets/index';
import { colorThemeSiteSettingsEpic } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import componentMainActions from './componentMainActions/index';
import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import { getThemeColorsFromStylesheet } from "../../../Workspace/epics/stylesheets/selectors";

export default {
    kind,
    calcRenderProps,
    componentMainActions,
    view,
    workspace,
    reducer,
    dependsOn: {
        webshopMHFData: policiesEpic.reducer,
        stylesheets: stylesheetsEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        themeColorsData: makeStateSelectorReducer(
            stylesheetsEpic.reducer,
            stylesheetsEpic.valueActionType,
            getThemeColorsFromStylesheet
        ),
    },
    minDimensions: {
        width: 300,
        height: 40,
    },
    mobileEditorConfig: previewConfig
};
