import view from "./view/index";
import kind from "./kind";
import calcRenderProps from './calcRenderProps';
import type { FacebookFeedGalleryComponent } from './flowTypes';
import type { CalcProps, CmpSpecificStyles } from "../../Preview/flowTypes";
import { getScaledGalleryImageStyles } from "../../../utils/getScaledStyles";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

const defaultSocialAccountsState = {
    facebookFeed: {
        facebookFeedAssetUrls: [],
        facebookFeedAssetsLoading: false
    }
};

export default {
    kind,
    view,
    calcProps: ({
        componentId,
        component,
        siteMap,
        globalStyles,
        globalVariables,
        previewBackupTime,
        componentsDependencies,
        isServerPreview,
        stylesheetsIdToNameMap,
        siteSettings: { themeSettingsData },
        selectedParentTheme
    }: CalcProps<FacebookFeedGalleryComponent>) => calcRenderProps({
        componentId,
        component,
        componentExtension: {},
        componentDependencies: {
            site: siteMap,
            missingAssetUrls: [],
            globalStyles,
            globalVariables,
            siteFonts: [],
            templateWidth: 0,
            defaultStyleId: componentsDependencies?.[kind] ? componentsDependencies[kind].defaultStyleId : {},
            stylesheetsIdToNameMap,
            socialAccounts: componentsDependencies?.[kind] ? componentsDependencies[kind].socialAccounts : defaultSocialAccountsState,
            themeColorsData: getThemeColorsFromStylesheet(globalStyles),
            themeSettingsData,
        },
        isWorkspace: false,
        previewBackupTime,
        inEditMode: false,
        isServerPreview,
        selectedParentTheme,
    }),
    getSpecificStyles: ({ component }: CmpSpecificStyles<FacebookFeedGalleryComponent>): string => {
        const scaledStyles = getScaledGalleryImageStyles(component);
        if (scaledStyles) {
            return `${scaledStyles}`;
        }
        return '';
    },
    js: [
        '/renderStatic/3rdparty/moment/moment.min.js',
        '/renderStatic/lightbox/shinybox/jquery.shinybox.modified.js',
        '/renderStatic/facebookFeedGallery/populateForPublish.js',
    ],
    css: [
        '/renderStatic/lightbox/shinybox/shinybox.css',
        '/renderStatic/lightbox/lightbox.css'
    ]
};
