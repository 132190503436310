export const
    WINDOW_OPEN = 'WINDOW_OPEN',
    WINDOW_LISTEN_FOR_UPGRADE_COMPLETE = 'WINDOW_LISTEN_FOR_UPGRADE_COMPLETE',

    WINDOW_LOCATION_RELOAD = 'WINDOW_LOCATION_RELOAD',
    WINDOW_NAVIGATE_TO_LOCATION = 'WINDOW_NAVIGATE_TO_LOCATION',
    WINDOW_OPEN_URL = 'WINDOW_OPEN_URL',

    REDIRECT_TO_TEMPLATE_SELECTOR = 'REDIRECT_TO_TEMPLATE_SELECTOR';

export const
    windowLocationReloadAction = () => ({ type: WINDOW_LOCATION_RELOAD }),
    windowNavigateToLocationAction = (location: string, timeout: number = 0) => ({
        type: WINDOW_NAVIGATE_TO_LOCATION,
        payload: { location, timeout }
    }),
    windowOpenUrlAction = (url: string) => ({
        type: WINDOW_OPEN_URL,
        payload: url
    });

