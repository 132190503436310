import * as React from 'react';
import cx from 'classnames';
import { DataRepositorySite, DataPageLayout } from "../../../../../../../dal/model";
import { PageLayoutCategoryId as Category } from "../../../../../../../dal/model/DataPageLayout";
import injectIntl, { Intl } from "../../../../../../view/intl/injectIntl";
import Scrollbar from '../../../../../../view/common/Scrollbar';
import getStyleIntValue from "../../../../../../utils/getStyleIntValue";
import { default as Loading } from '../../../../../../view/common/LoadingIndicator';
import type { NewPageDialogLayoutPageProps } from "../../../flowTypes";
import { selectPageLayoutAction } from "../../../actionCreators";
import { HoverBoxCom } from "../../../../../presentational/HoverBox";
import type { ReactElementRef } from "../../../../../../globalTypes";
import { HoverBoxPreviewBtnCom } from "../../../../../presentational/HoverBoxControls";
import type { SubscriptionData } from '../../../../../../redux/modules/flowTypes';
import { isDemoSubscriptionType } from '../../../../../App/epics/subscriptionData/isDemoSubscriptionType';
import { getDAL } from '../../../../../../../dal';
import { Contact } from "../../../../../Panel/configs/constants";
import type { WsbFeatureT } from "../../../../../../../../server/shared/WsbFeature";
import { WsbFeature } from "../../../../../../../../server/shared/WsbFeature";
import { isWsbFeatureEnabled } from "../../../../../ComponentTierManager/utils";
import Combobox from '../../../../../../view/common/Combobox';
import ICONS from "../../../../../../view/Icons/index";
import * as styles from './LayoutPageTab.css';
import { isPartnerSubdomain } from "../../../../../ComponentTierManager/partnerSubdomainNameUtils";
import getSubscriptionFeatureManager from '../../../../../../getSubscriptionFeatureManager';

const genericCategory = '0';
const blankCategory = '1';
const productsCategory = '13';

const PageLayoutCategoryId = {
    GENERIC: genericCategory,
    BLANK_PAGE: blankCategory,
    ABOUT: '3',
    GALLERY: '4',
    PORTFOLIO: '8',
    WEBSHOP: '9',
    CONTACT: '5',
    PRICE_LIST: '10',
    CV: '7',
    VIDEO: '12',
    NEWS: '6',
    FAQ: '11',
    LANDING_PAGE: '2',
    PRODUCTS: '13',
    SERVICES: '14',
    SOCIAL: '15',
    EVENTS: '16'
};

const PageLayoutCategories = {
    [PageLayoutCategoryId.LANDING_PAGE]: 'msg: common.landingPage {Landing page}',
    [PageLayoutCategoryId.ABOUT]: 'msg: common.about {About}',
    [PageLayoutCategoryId.GALLERY]: 'msg: component.gallery.label {Gallery}',
    [PageLayoutCategoryId.CONTACT]: Contact,
    [PageLayoutCategoryId.NEWS]: 'msg: common.news {News}',
    [PageLayoutCategoryId.CV]: 'msg: common.cv {CV}',
    [PageLayoutCategoryId.PORTFOLIO]: 'msg: common.portfolio {Portfolio}',
    [PageLayoutCategoryId.WEBSHOP]: 'msg: component.webshop {Online Shop}',
    [PageLayoutCategoryId.PRICE_LIST]: 'msg: common.priceList {Price list & Menu}',
    [PageLayoutCategoryId.FAQ]: 'msg: common.faq {FAQ}',
    [PageLayoutCategoryId.VIDEO]: 'msg: common.video {Video}',
    [PageLayoutCategoryId.PRODUCTS]: 'msg: common.products {Products}',
    [PageLayoutCategoryId.SERVICES]: 'msg: common.services {Services}',
    [PageLayoutCategoryId.SOCIAL]: 'msg: common.social {Social}',
    [PageLayoutCategoryId.EVENTS]: 'msg: common.events {Events}'
};

const PageLayoutCategoryWsbFeatureMap: Record<string, WsbFeatureT> = {
    [PageLayoutCategoryId.WEBSHOP]: WsbFeature.OnlineShop,
    [PageLayoutCategoryId.PRODUCTS]: WsbFeature.OnlineShop,
};

type CategoryMap = {
    [key: string]: {
        id: string;
        label: string;
        items: Array<DataPageLayout>
    }
};

type Props = NewPageDialogLayoutPageProps & {
    dispatch: Dispatch;
    intl: Intl;
    addPageHandler: SimpleFunction;
    subscriptionData: SubscriptionData;
    showUpgradeMsg: boolean;
};

type State = {
    selectedCategory: string,
    showAllTemplates: boolean
}

export default injectIntl(class LayoutPageTab extends React.Component<Props, State> {
    previewTargetRef: ReactElementRef<HTMLSpanElement>;
    categoriesToSkip: Record<string, any>;
    layoutTabHeight: number;

    constructor(props: Props) {
        super(props);

        this.previewTargetRef = React.createRef();

        this.categoriesToSkip = {
            [genericCategory]: true,
            [blankCategory]: true,
            [productsCategory]: !getSubscriptionFeatureManager().isOnlineShopCmpsAllowed()
        };
        this.state = {
            selectedCategory: genericCategory,
            showAllTemplates: false
        };

        if (isDemoSubscriptionType(props.subscriptionData)) {
            this.categoriesToSkip[Category.WEBSHOP] = true;
            this.categoriesToSkip[Category.PRODUCTS] = true;
        }

        Object.entries(PageLayoutCategoryWsbFeatureMap).forEach(([category, wsbFeature]) => {
            if (!isWsbFeatureEnabled((wsbFeature as any))) {
                this.categoriesToSkip[category] = true;
            }
        });

        this.layoutTabHeight = props.showUpgradeMsg ?
            getStyleIntValue(styles, 'layoutContainerWithUpgradeMessageHeight') :
            getStyleIntValue(styles, 'layoutContainerHeight');
    }

    getSelectedCategory(layoutList) {
        if (
            layoutList[0] &&
            layoutList[0].value !== genericCategory &&
            this.state.selectedCategory === genericCategory
        ) {
            this.setState({ selectedCategory: "-1" });
        }
        return this.state.selectedCategory;
    }

    getCategoryMapAndSequence() {
        const repositorySiteData: DataRepositorySite | null | undefined = this.props.repositorySiteData;
        if (!repositorySiteData) return {};

        // const layouts = repositorySiteData.getPublicLayouts(),
        const layouts = repositorySiteData.pageLayouts,
            categoryMap: CategoryMap = {},
            categorySequence: Array<string> = [],
            blankLayout: Record<string, any> = layouts.find(pageLayout => PageLayoutCategoryId[pageLayout.category] === blankCategory);

        layouts.forEach((pageLayout: DataPageLayout) => {
            const
                // categoryId = pageLayout.getCategory().id,
                categoryId = PageLayoutCategoryId[pageLayout.category],
                category = categoryMap[categoryId] || { items: [{ ...blankLayout, category: pageLayout.category, isBlankLayout: true }] };
            if (this.categoriesToSkip[categoryId]) return;

            category.id = categoryId;
            // category.label = pageLayout.getCategory().label;
            category.label = PageLayoutCategories[category.id];
            category.items.push(pageLayout);
            if (!categoryMap[categoryId]) {
                categorySequence.push(categoryId);
            }
            categoryMap[categoryId] = category;
        });

        if (isPartnerSubdomain()) {
            const skipProducts = catId => catId !== PageLayoutCategoryId.PRODUCTS;

            return {
                categoryMap: Object
                    .keys(categoryMap)
                    .filter(skipProducts)
                    .reduce((acc, categoryId) => {
                        return { ...acc, [categoryId]: categoryMap[categoryId] };
                    }, {}),
                categorySequence: categorySequence
                    .filter(skipProducts),
            };
        }

        return { categoryMap, categorySequence };
    }

    renderCategory(category: Record<string, any>, showLabel = true) {
        const
            { addPageHandler, intl, dispatch } = this.props,
            categoryLabel = intl.msgJoint(category.label);
        let selectedLayout = this.props.selectedLayout;

        const getThumbnailUrl = function (layout) {
            return getDAL().makePageLayoutThumbnailUrl(layout.pageId);
        };

        const getPreviewUrl = function (layout) {
            return getDAL().makePageLayoutPreviewUrl(layout.pageId);
        };

        return (
            <div className={styles.layoutCategory} key={category.id}>
                {showLabel && <div>{categoryLabel}</div>}
                <div className={styles.layoutContainer}>
                    {category.items.map((layout: DataPageLayout) => {
                        const
                            selectLayout = () => {
                                dispatch(selectPageLayoutAction(layout));
                            },
                            // isSelected = selectedLayout && selectedLayout.id === layout.id || false,
                            isSelected = (
                                selectedLayout &&
                                selectedLayout.pageId === layout.pageId &&
                                selectedLayout.category === layout.category) || false,
                            // label = layout.isBlank() ? categoryLabel : layout.getName(),
                            label = layout.name;

                        return (
                            <div>
                                {layout.isBlankLayout ?
                                    <div
                                        className={cx(
                                            styles.blankLayoutContainer,
                                            { [styles.selected]: isSelected }
                                        )}
                                        onClick={selectLayout}
                                        onDoubleClick={addPageHandler}
                                    >
                                        <div className={styles.blankLayout}>
                                            <ICONS.BLANK_PAGE_ICON />
                                            <div className={styles.blankLabel}>
                                                {intl.msgJoint("msg: common.blankPage {Blank page}")}
                                            </div>
                                        </div>
                                        <span className={styles.cornerSelectedIcn} />
                                    </div> :
                                    <HoverBoxCom
                                        // bgSrc={layout.getThumbnailUrl()}
                                        bgSrc={getThumbnailUrl(layout)}
                                        selected={isSelected}
                                        theme={{
                                            box: styles.layout
                                        }}
                                        onClick={selectLayout}
                                        onDoubleClick={addPageHandler}
                                        // key={layout.id}
                                        key={layout.pageId}
                                    >
                                        <span className={styles.layoutLabel}>{label}</span>
                                        <HoverBoxPreviewBtnCom
                                            btnTitle={false}
                                            // src={layout.getPreviewUrl()}
                                            src={getPreviewUrl(layout)}
                                            previewClassName={styles.layoutPreview}
                                        />
                                    </HoverBoxCom>}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderSelectedCategories(categoryMap, categorySequence) {
        const
            isAllCategorySelected = this.state.selectedCategory === '-1';
        return (
            <div>
                {isAllCategorySelected ?
                    (
                        categoryMap && (
                            categorySequence.map(categoryId =>
                                this.renderCategory(categoryMap[categoryId]))
                        )
                    ) :
                    this.renderCategory(categoryMap[this.state.selectedCategory], false)}
            </div>
        );
    }

    renderBody() {
        const
            { intl, recommendedLayouts } = this.props,
            { categoryMap, categorySequence } = this.getCategoryMapAndSequence(),
            height = this.layoutTabHeight,
            pageLayoutList: Array<any> = [];

        if (recommendedLayouts && recommendedLayouts.items.length) {
            pageLayoutList.push({
                label: intl.msgJoint("msg: common.recommended {Recommended}"),
                value: genericCategory
            });
        }
        pageLayoutList.push({
            label: intl.msgJoint("msg: common.blocks.all {All}"),
            value: '-1'
        });

        // @ts-ignore
        categorySequence.map(categoryId => pageLayoutList.push({ label: intl.msgJoint(categoryMap[categoryId].label), value: categoryId }));

        return (
            <div>
                <div className={styles.categoryComboBoxWrapper}>
                    <Combobox
                        options={pageLayoutList}
                        searchable={false}
                        disable={false}
                        value={this.getSelectedCategory(pageLayoutList)}
                        className={styles.categoryComboBox}
                        onChange={({ value }) => {
                            this.setState({ selectedCategory: value });
                        }}
                    />
                </div>
                <Scrollbar height={height}>
                    <div className={styles.categoriesContainer}>
                        {
                            this.state.selectedCategory === genericCategory ? (
                                <div>
                                    {this.renderCategory(recommendedLayouts!)}
                                    <a
                                        onClick={() => {
                                            const visible = this.state.showAllTemplates;
                                            this.setState({ showAllTemplates: !visible });
                                        }}
                                        className={cx(
                                            styles.anchorLink,
                                            styles.moreTemplatesLink,
                                            { [styles.expanded]: this.state.showAllTemplates }
                                        )}
                                    >
                                        {
                                            this.state.showAllTemplates ?
                                                intl.msgJoint("msg: newPage.newPage.seeLessTemplates {See less templates}") :
                                                intl.msgJoint("msg: newPage.newPage.seeMoreTemplates {See more templates}")
                                        }
                                    </a>
                                    {this.state.showAllTemplates && categoryMap && (
                                        categorySequence.map(categoryId =>
                                            this.renderCategory(categoryMap[categoryId]))
                                    )}
                                </div>
                            ) : this.renderSelectedCategories(categoryMap, categorySequence)
                        }
                    </div>
                </Scrollbar>
            </div>
        );
    }

    render() {
        return this.props.isLoading
            ? <Loading height={this.layoutTabHeight} />
            : this.renderBody();
    }
});
