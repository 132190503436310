import { linkedValueAttr, linkedValueTag } from '../constants';
import { getDAL } from '../../../../../dal/index';
import { andChar } from '../../../App/epics/tinyMceEpic/constants';
import { GlobalVariablesKeysMap } from '../../../App/epics/globalVariablesEpic';
import { replaceTagsWithContent, fixInvalidHrefs } from './replaceTagsWithContent';
import { Deps } from './flowTypes';

export const replaceTagsWithContentDOM = (content: string, deps: Deps, shouldMatchAgainstExceptionList: boolean = false) => {
    // TODO: (WBTGEN-11968) remove when linked-values are to be made available to all
    let canUseLinkedValues = getDAL().isRepositoryDomain();
    const psuedoDom: HTMLDivElement = document.createElement('div');
    psuedoDom.innerHTML = content;

    Array.from(psuedoDom.querySelectorAll(linkedValueTag)).forEach(linkedValueElt => {
        let fieldName = linkedValueElt.getAttribute(linkedValueAttr);
        if (!fieldName) {
            linkedValueElt.remove();
            return;
        }

        let substituteNode;

        if (canUseLinkedValues) {
            substituteNode = linkedValueElt;
        } else /* isBeforeImport case */ { // TODO: (WBTGEN-11968) remove when linked-values are to be made available to all
            substituteNode = document.createTextNode('');
            if (linkedValueElt.parentElement) {
                linkedValueElt.parentElement.insertBefore(substituteNode, linkedValueElt);
            }
            linkedValueElt.remove();
        }
        const indexOfAndChar = fieldName.indexOf(andChar);
        if (indexOfAndChar !== -1) {
            const translationFieldName = fieldName.substring(0, fieldName.indexOf(andChar));
            const linkedValueFieldName = fieldName.substring(indexOfAndChar + 1);

            if (deps.globalVariables && deps.globalVariables[linkedValueFieldName] && GlobalVariablesKeysMap[linkedValueFieldName]) {
                fieldName = linkedValueFieldName;
            } else {
                fieldName = translationFieldName;
            }
        }

        if (!deps.globalVariables || !deps.globalVariables[fieldName])
            return;

        // eslint-disable-next-line no-param-reassign
        substituteNode.textContent = deps.globalVariables[fieldName];
    });

    const contentWithReplacedTags = replaceTagsWithContent(psuedoDom.innerHTML, deps, shouldMatchAgainstExceptionList);
    return fixInvalidHrefs(contentWithReplacedTags);
};
