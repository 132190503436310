module.exports = function isNewSeoEnabled(domainName, createdTimestamp, oneWebConfig){
    if(oneWebConfig){
        const newSeoEnabled = oneWebConfig.webshop.newSeoEnabled;
        const newSEOEnabledWhitelistedDomains = oneWebConfig.webshop.newSEOEnabledWhitelistedDomains;
        const newSEOEnabledTimestamp = oneWebConfig.webshop.newSEOEnabledTimestamp;
        if (newSeoEnabled &&
            (newSEOEnabledWhitelistedDomains.includes(domainName) ||
            (newSEOEnabledTimestamp  && createdTimestamp && createdTimestamp > newSEOEnabledTimestamp))) {
                return true;
        }
    }
    return false;
};
