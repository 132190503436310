import { getDAL } from "../init";
import { Contact } from "../../src/components/Panel/configs/constants";

const PageLayoutCategoryId = {
    BLANK_PAGE: '1',
    ABOUT: '3',
    GALLERY: '4',
    PORTFOLIO: '8',
    WEBSHOP: '9',
    CONTACT: '5',
    PRICE_LIST: '10',
    CV: '7',
    VIDEO: '12',
    NEWS: '6',
    FAQ: '11',
    LANDING_PAGE: '2',
    PRODUCTS: '13'
};

const PageLayoutCategories = {
    [PageLayoutCategoryId.BLANK_PAGE]: 'msg: common.blankPage {Blank page}',
    [PageLayoutCategoryId.LANDING_PAGE]: 'msg: common.landingPage {Landing page}',
    [PageLayoutCategoryId.ABOUT]: 'msg: common.about {About}',
    [PageLayoutCategoryId.GALLERY]: 'msg: component.gallery.label {Gallery}',
    [PageLayoutCategoryId.CONTACT]: Contact,
    [PageLayoutCategoryId.NEWS]: 'msg: common.news {News}',
    [PageLayoutCategoryId.CV]: 'msg: common.cv {CV}',
    [PageLayoutCategoryId.PORTFOLIO]: 'msg: common.portfolio {Portfolio}',
    [PageLayoutCategoryId.WEBSHOP]: 'msg: component.webshop {Online Shop}',
    [PageLayoutCategoryId.PRICE_LIST]: 'msg: common.priceList {Price list & Menu}',
    [PageLayoutCategoryId.FAQ]: 'msg: common.faq {FAQ}',
    [PageLayoutCategoryId.VIDEO]: 'msg: common.video {Video}'
};

class DataPageLayout {
    type!: "web.data.links.LinkPage";
    id!: string;
    pageId!: string;
    name!: string;
    title!: string;
    url!: string;
    items!: Record<string, any>[];
    categories!: Array<string>;
    // TODO!: We shall move this out since the new structure for pageLayouts list is much different amd simpler
    category!: string;
    accountName!: string;
    description!: string;
    hidden!: boolean;
    public!: boolean;
    robots!: string;
    isBlankLayout?: boolean;

    constructor(data: Record<string, any>) {
        Object.assign(this, data);
    }

    // TODO: It is probably not being used anymore
    getCategory() {
        const categoryId = this.categories[0];
        return { id: categoryId, label: PageLayoutCategories[categoryId] };
    }

    isBlank() {
        return this.getCategory().id === PageLayoutCategoryId.BLANK_PAGE;
    }

    getName() {
        return this.name.replace(/Layout\s*-\s*/, '');
    }

    getThumbnailUrl() {
        return getDAL().makePageLayoutThumbnailUrl(this.pageId);
    }

    getPreviewUrl() {
        return getDAL().makePageLayoutPreviewUrl(this.pageId);
    }
}

export { DataPageLayout as default, PageLayoutCategoryId };
