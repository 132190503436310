export const Lit = {
    templates: 'templates',
    template: 'template',
    isLoading: 'isLoading',
    onHold: 'onHold',
    itemsOnHold: 'itemsOnHold',
    selectedId: 'selectedId',
    selected: 'selected',
    previewId: 'previewId',
    pageSets: 'pageSets',
    isEqToInitialState: 'isEqToInitialState',
    templatesAreLoading: 'templatesAreLoading',
    layoutPage: 'layoutPage',
    selectedLayout: 'selectedLayout',
    selectedTemplate: 'selectedTemplate',
    isLayoutLoading: 'isLayoutLoading',
    repositorySiteData: 'repositorySiteData',
    page: 'page',
    name: 'name',
    names: 'names',
    publishStatus: 'publishStatus',
    entries: 'entries',
    background: 'background',
    backgroundColor: 'backgroundColor',
    asset: 'asset',
    url: 'url',
    style: 'style',
    assetData: 'assetData',
    showTip: 'showTip',
    id: 'id',
    activeTabId: 'activeTabId',
    showPreviewId: 'showPreviewId',
    orientation: 'orientation',
    isPortraitMode: 'isPortraitMode',
    isLandscapeMode: 'isLandscapeMode',
    search: 'search',
    categories: 'categories',
    downloads: 'downloads',
    imageType: 'imageType',
    isTypeImages: 'isTypeImages',
    mctaDisabled: 'mctaDisabled',
    imagePrice: "imagePrice",
    skipComponentActionPostDownload: "skipComponentActionPostDownload",
    isTypeIllustrations: 'isTypeIllustrations',
    images: 'images',
    category: 'category',
    categoriesLoading: 'categoriesLoading',
    downloadsLoading: 'downloadsLoading',
    isUploading: 'isUploading',
    imagesLoading: 'imagesLoading',
    selectedImageIds: 'selectedImageIds',
    fileChooser: 'fileChooser',
    freeOnecom: 'freeOnecom',
    imagesInitialized: 'imagesInitialized',
    query: 'query',
    categoryId: 'categoryId',
    isMultiSelect: 'isMultiSelect',
    showPremiumDownloadsMsg: 'showPremiumDownloadsMsg',
    perPage: 'perPage',
    imagesPageLoading: 'imagesPageLoading',
    initialized: 'initialized',
    gridScrollTop: 'gridScrollTop',
    oneWeb: 'oneWeb',
    shutterstock: 'shutterstock',
    quota: 'quota',
    epics: 'epics',
    state: 'state',
    current: 'current',
    language: 'language',
    emptySearchResults: 'emptySearchResults',
    selectedImages: 'selectedImages',
    partialDownloads: 'partialDownloads',
    filters: 'filters',
    pages: 'pages',
    items: 'items',
    bbox: 'bbox',
    etag: 'etag',
    type: 'type',
    templateId: 'templateId',
    value: 'value',
    isTouched: 'isTouched',
    isValid: 'isValid',
    errors: 'errors',
    folder: 'folder',
    selection: 'selection',
    show: 'show',
    demo: 'demo',
    bottomBar: 'bottomBar',
    status: 'status',
    beforeTourStatus: 'beforeTourStatus',
    collapseBottomBar: 'collapseBottomBar',
    firstLoaded: 'firstLoaded',
    selectedWorkspace: 'selectedWorkspace',
    preferences: 'preferences',
    _rev: '_rev',
    whatsNew: 'whatsNew',
    list: 'list',
    login: 'login',
    count: 'count',
    whatsNewLastSeenTimestamp: 'whatsNewLastSeenTimestamp',
    latestTimestamp: 'latestTimestamp',
    mock: 'mock',
    hideWhatsNewTip: 'hideWhatsNewTip',
    subject: 'subject',
    unwrapedTipVisible: 'unwrapedTipVisible',
    instance: 'instance',
    generalData: 'generalData',
    mobileViewEditor: 'mobileViewEditor',
    mobile: 'mobile',
    logo: 'logo',
    logoUrl: 'logoUrl',
    logoAsset: 'logoAsset',
    title: 'title',
    resource: 'resource',
    websiteTitle: 'websiteTitle',
    navigation: 'navigation',
    dimensions: 'dimensions',
    position: 'position',
    cartData: 'cartData',
    isCartWebshopPageOnly: 'isCartWebshopPageOnly',
    showMobileCart: 'showMobileCart',
    webshopPageIds: 'webshopPageIds',
    isHeaderSelected: 'isHeaderSelected',
    color: 'color',
    fontFamily: 'fontFamily',
    bold: 'bold',
    italic: 'italic',
    useCustomStyle: 'useCustomStyle',
    menu: 'menu',
    closed: 'closed',
    open: 'open',
    iconColor: 'iconColor',
    fontWeight: 'fontWeight',
    fontStyle: 'fontStyle',
    fontSize: 'fontSize',
    lineHeight: 'lineHeight',
    underline: 'underline',
    fonts: 'fonts',
    useColor: 'useColor',
    showBottomShadow: 'showBottomShadow',
    showBottomBorder: 'showBottomBorder',
    isMenuOpen: 'isMenuOpen',
    fontStatus: 'fontStatus',
    imagesSearchId: 'imagesSearchId',
    headerSection: 'headerSection',
    footerSection: 'footerSection',
    pin: 'pin',
    pageId: 'pageId',
    sectionId: 'sectionId',
    signup: 'signup',
    signin: 'signin',
    resetPassword: 'resetPassword',
    forgotPassword: 'forgotPassword',
    currentPage: 'currentPage',
    trialDaysRemaining: 'trialDaysRemaining',
    trialDomainNameForPurchase: 'trialDomainNameForPurchase',
    trialUserSubId: 'trialUserSubId',
    trialUserToken: 'trialUserToken',
    trialUserEmailVerified: 'trialUserEmailVerified',
    controlPanelUser: 'controlPanelUser',
    verificationEmailRequired: 'verificationEmailRequired',
    email: 'email',
    domain: 'domain',
    trialDataImporting: 'trialDataImporting',
    trialImported: 'trialImported',
    trialImportAvailability: 'trialImportAvailability',
    trialImportAvailabilityData: 'trialImportAvailabilityData',
    trialImportAvailable: 'trialImportAvailable',
    trialImportEmail: 'trialImportEmail',
    trialImportSubID: 'trialImportSubID',
    trialImportToken: 'trialImportToken',
    trialImportAvailabilityScreenShot: 'trialImportAvailabilityScreenShot',
    socialAccounts: 'socialAccounts',
    instagram: 'instagram',
    instagramConnected: 'instagramConnected',
    instagramAccessToken: 'instagramAccessToken',
    instagramAssetUrls: 'instagramAssetUrls',
    instagramAssetsLoading: 'instagramAssetsLoading',
    instagramAccountName: "instagramAccountName",
    totalInstagramGalleryComponents: "totalInstagramGalleryComponents",
    totalCurrentPageInstagramGalleryComponents: "totalCurrentPageInstagramGalleryComponents",
    totalOtherPagesInstagramGalleryComponents: "totalOtherPagesInstagramGalleryComponents",
    facebookFeed: 'facebookFeed',
    facebookFeedAssetsLoading: "facebookFeedAssetsLoading",
    facebookFeedConnected: "facebookFeedConnected",
    facebookFeedAccessToken: "facebookFeedAccessToken",
    facebookFeedAssetUrls: "facebookFeedAssetUrls",
    facebookFeedAccountName: "facebookFeedAccountName",
    isFbPageMode: "isFbPageMode",
    managedFbPages: "managedFbPages",
    fbSelectedPageValue: "fbSelectedPageValue",
    totalFacebookFeedGalleryComponents: "totalFacebookFeedGalleryComponents",
    totalCurrentPageFacebookFeedGalleryComponents: "totalCurrentPageFacebookFeedGalleryComponents",
    totalOtherPagesFacebookFeedGalleryComponents: "totalOtherPagesFacebookFeedGalleryComponents",
    facebookFeedAccountId: "facebookFeedAccountId",
    featuredProductsPageIds: "featuredProductsPageIds",
    facebookChat: 'facebookChat',
    facebookChatConnected: 'facebookChatConnected',
    facebookChatAccessToken: 'facebookChatAccessToken',
    facebookChatAccountName: 'facebookChatAccountName',
    facebookChatAccountId: 'facebookChatAccountId',
    fbPageProfilePicUrl: 'fbPageProfilePicUrl',
    facebookChatLocale: 'facebookChatLocale',
    facebookChatColor: 'facebookChatColor',
    chatWidgetData: 'chatWidgetData',
    hideAutosavePopUp: 'hideAutosavePopUp'
} as const;
