import React from 'react';
import TopBarItemMenu from "../index";
import TopBarItemContainer from '../../TopBarItemContainer';
import { Msg, injectIntl } from "../../../../../view/intl/index";
import type { TopBarUpgradeMenuProps } from "../../../flowTypes";
import UpgradeOption from '../../UpgradeOption/index';
import styles from "../../TopBar.css";
import { topBarClosePopupAction } from "../../../actionCreators/index";
import OpenUpgradePlanDialog from "../../Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { getTopbarUpgradeMsg } from "../../utils";
import { isSeamlessUpgradeDisabledByConfig } from "../../../../ComponentTierManager/utils";
import { isPagesOrSectionLinksLimitExceededGivenSubscriptionType } from '../../../../App/epics/subscriptionData/utils';
import { isPagesOrSectionLinksLimitExceededGivenSiteDataAndSubscriptionType } from '../../../../App/epics/siteData/utils/pages';
import { upgradeDialogOpenedAction } from '../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_TOP_BAR } from '../../Upgrade/tracking/contants';
import { UpgradePlansDialogId } from '../../dialogIds';

const seamlessUpgradeDisabledByConfig = isSeamlessUpgradeDisabledByConfig();
type TopBarUpgradeMenuState = {
    showUpgrade: boolean;
};

class TopBarItemMenuUpgrade extends React.PureComponent<TopBarUpgradeMenuProps, TopBarUpgradeMenuState> {
    timerId: ReturnType<typeof setTimeout> | null;

    constructor(props: TopBarUpgradeMenuProps) {
        super(props);
        this.state = {
            showUpgrade: false
        };
        this.onUpgradeMenuClickHandler = this.onUpgradeMenuClickHandler.bind(this);
        this.onMouseOverHandler = this.onMouseOverHandler.bind(this);
        this.onMouseOutHandler = this.onMouseOutHandler.bind(this);

        this.timerId = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps: TopBarUpgradeMenuProps) {
        const { pagesTree } = nextProps;
        if (!pagesTree || !pagesTree.site || !this.props.pagesTree || !this.props.pagesTree.site) {
            return;
        }

        // on page load or on add page check if we want to show upgrade notification
        const
            nextPageCount = pagesTree.site.getAllPageRefs().filter(page => page.public).length || 0,
            currentPageCount = this.props.pagesTree.site.getAllPageRefs().filter(page => page.public).length || 0,
            nextSectionLinkCount = pagesTree.site.getAllSectionLinkRefs().filter(page => !page.hidden).length || 0,
            currentSectionLinkCount = this.props.pagesTree.site.getAllSectionLinkRefs().filter(page => !page.hidden).length || 0;

        if ((nextPageCount > currentPageCount || nextSectionLinkCount > currentSectionLinkCount) &&
            isPagesOrSectionLinksLimitExceededGivenSubscriptionType(
                nextPageCount,
                nextSectionLinkCount,
                this.props.subscriptionData.subscriptionType
            )) {
            this.showUpgrade(true);
            this.hideUpgradeOption();
        }
    }

    showUpgrade(showUpgrade: boolean) {
        this.setState({ showUpgrade });
    }

    // eslint-disable-next-line class-methods-use-this
    topBarUpgradeIcon() {
        return (<div className={styles.topbarUpgrade} />);
    }

    onUpgradeMenuClickHandler() {
        const { upgradeStatus, subscriptionData } = this.props;

        if (upgradeStatus.isUpgrading) return;
        this.props.dispatch(OpenUpgradePlanDialog(subscriptionData, 'Workspace:TopBlueBarBtn'));
        this.props.dispatch(upgradeDialogOpenedAction({
            feature: FEATURE_TOP_BAR,
            dialogId: UpgradePlansDialogId
        }));
    }

    onMouseOverHandler() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        this.props.dispatch(topBarClosePopupAction());
        this.showUpgrade(true);
    }

    onMouseOutHandler() {
        this.hideUpgradeOption(150);
    }

    hideUpgradeOption(msec: number = 5000) {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        this.timerId = setTimeout(() => {
            this.showUpgrade(false);
        }, msec);
    }

    render() {
        const
            { subscriptionData, upgradeStatus, dispatch, componentTierManagerState, pagesTree, intl } = this.props,
            { availableUpgrades, subscriptionType, metadata: subscriptionMetadata } = subscriptionData,
            disableUpgradeMenu = upgradeStatus.isUpgrading,
            availableUpgrade = availableUpgrades && availableUpgrades[0],
            isFreeUpgrade = !seamlessUpgradeDisabledByConfig && availableUpgrade && availableUpgrade.freeUpgradeAvailable,
            msgUpgrade = isFreeUpgrade
                ? <Msg className={styles.purpleText} k="web.UpgradeButtonFree">Free Upgrade</Msg>
                : <Msg className={styles.purpleText} k="web.UpgradeButton">Upgrade</Msg>,
            saveStatus = pagesTree.saveStatus;

        const upgradeOptionUpgradeMsg = getTopbarUpgradeMsg({
            subscriptionType,
            subscriptionMetadata,
            componentTierManagerState,
            isPagesOrSectionsExceeded: isPagesOrSectionLinksLimitExceededGivenSiteDataAndSubscriptionType(pagesTree.site, subscriptionType),
            saveStatus,
            intl,
        });

        return (
            <TopBarItemContainer
                left
                disableMouseDown
                extraClassName={styles.displayPremiumorUpgrade}
                onMouseOver={this.onMouseOverHandler}
                onMouseOut={this.onMouseOutHandler}
            >
                {this.state.showUpgrade &&
                    <UpgradeOption
                        dispatch={dispatch}
                        onMouseOver={this.onMouseOverHandler}
                        onMouseOut={this.onMouseOutHandler}
                        upgradeMsg={upgradeOptionUpgradeMsg}
                        subscriptionData={subscriptionData}
                    />}
                <TopBarItemMenu
                    iconStyle={{ pointerEvents: 'none' }}
                    disabled={disableUpgradeMenu}
                    onClick={this.onUpgradeMenuClickHandler}
                    LeftSideIcon={this.topBarUpgradeIcon()}
                >
                    {msgUpgrade}
                </TopBarItemMenu>
            </TopBarItemContainer>
        );
    }
}

export default injectIntl(TopBarItemMenuUpgrade);
