import { all, take, fork, put, takeLatest } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { raceGen, takeSuccessFromApiActionGen } from "../../utils/saga/index";
import loadResources from "../App/actionCreators/loadResources";
import {
    SAVE_EDITED_IMAGE,
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS,
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS,
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE,
    IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG,
    OPEN_IMAGE_EDITOR_DIALOG,
    IMAGE_EDITOR_SERIALIZED_SAVE_CLOSE_EDITOR
} from './actionTypes';
import { dialogId } from "../../view/common/dialogs/ImageEditorDialog/index";
import { MessageCodes } from './Messages';
import { getAssetFileInfo } from '../../utils/assetUtils';
import getResourceMetadataSaga from "../../redux/modules/children/fileChooser/reducers/getResourceMetadataSaga";
import { makeActionForwardToSelectedComponent } from "../../redux/forwardTo";
import { updateImageEditorMessage } from "./actionCreators";
import type { AssetFileInfo } from "../../utils/assetUtils";
import languagesValueActionType from '../TopBar/epics/languages/valueActionType';
import { openDialog } from "../App/actionCreators/index";
import selectGen from "../../utils/saga/selectGen";
import type { AppState } from "../../redux/modules/flowTypes";
import uploadFromComputerApiAction from "../../redux/modules/children/fileChooser/actionCreators/uploadFromComputerApiAction";
import { fcCreateWebSpaceFolder } from "../../redux/modules/children/fileChooser/actionCreators/index";
import { FC_ONEWEBMEDIA_PATH } from "../../redux/modules/children/fileChooser/constants";
import {
    FC_COMPUTER_UPLOAD_FAILURE,
    FC_COMPUTER_UPLOAD_SUCCESS
} from "../../redux/modules/children/fileChooser/actionTypes";
import { editedImageNameSuffix } from './constants';
import { decodeWebspaceUri } from '../../../dal/utils/webspaceUriTransformers';

const blobToFile = (theBlob: Blob, fileName: string) => {
    const
        newBlob = theBlob.slice(),
        ts = Date.now();

    // @ts-ignore
    newBlob.lastModifiedDate = new Date(ts);
    // @ts-ignore
    newBlob.lastModified = ts;
    // @ts-ignore
    newBlob.name = fileName;

    return newBlob;
};
const
    contentTypeFilter = /image\/(?:gif|png|jpg|jpeg|x-icon|vnd.microsoft.icon)/, // should actually be globalised for image, but it aint
    /*
     * Returns the new image path based on the old name and newId
     * @param {object} fileInfo
     * @param {number} newId the number to append to get the new file name from the old
     */
    _getNewImageName = (fileInfo: AssetFileInfo, newId: number, fileNameSuffix: string): string => {
        let imageName = fileInfo.name;
        let suffixFileNamePattern = new RegExp(`${fileNameSuffix}\\d*\.[a-zA-Z]*$`);
        if (suffixFileNamePattern.test(imageName)) {
            // @ts-ignore
            imageName = imageName.replace(/(\d+)$/, newId) + '.' + fileInfo.extn;   //NOSONAR
        } else {
            imageName = imageName + fileNameSuffix + newId + '.' + fileInfo.extn;
        }
        return fileInfo.dir + imageName;
    },
    getNewFileName = (entries: Array<any>, fileInfo: AssetFileInfo, fileNameSuffix: string): string => {
        let maxFileNumber = 0;
        let fileName = escape(decodeURI(fileInfo.name));
        let suffixFileNamePattern = new RegExp(`${fileNameSuffix}\\d*\.[a-zA-Z]*$`);
        let hasHiddenEditedImageNameSuffix = suffixFileNamePattern.test(fileName);
        // eslint-disable-next-line
        let fileNameWithoutNumberSuffix = hasHiddenEditedImageNameSuffix ? fileName.replace(/\d*$/g, '') : (fileName + fileNameSuffix); //NOSONAR

        let regex = new RegExp('^' + fileNameWithoutNumberSuffix  + '(\\d*)\\.' + fileInfo.extn + '$', 'g'); // eslint-disable-line
        entries.forEach(function (entry) {
            if (contentTypeFilter.test(entry.contentType)) {
                let match = regex.exec(escape(decodeURI(entry.href)));
                if (match) {
                    let num = parseInt(match[1], 10);
                    if (!isNaN(num) && num > maxFileNumber) {
                        maxFileNumber = num;
                    }
                }
            }
            regex.lastIndex = 0;
        });
        return _getNewImageName(fileInfo, maxFileNumber + 1, fileNameSuffix);
    },
    getDisplayFileName = (filename: string, extn: string, fileNameSuffix: string): string => {
        const
            ellipsis = '...',
            suffixFileNamePattern = new RegExp(`${fileNameSuffix}\\d*\.[a-zA-Z]*$`),
            indexOfSuffix = filename.lastIndexOf(fileNameSuffix);

        let maxNornalNameWithoutExtension = 8; // 8 "m"

        if (extn.length === 4) {
            maxNornalNameWithoutExtension -= 1;
        }

        if (!suffixFileNamePattern.test(filename)) {
            return filename.substring(0, maxNornalNameWithoutExtension) + ellipsis + extn;
        }

        const
            fileSuffixAndExtension = filename.substring(indexOfSuffix),
            originalFilenameWithoutExtension = filename.substring(0, indexOfSuffix);

        // if file name suffix length changes then subtract the change to max length of allowed characters
        // for example filename suffix changes to "_edited100.jpg"
        maxNornalNameWithoutExtension -= fileSuffixAndExtension.length - (fileNameSuffix.length + 4);

        if (maxNornalNameWithoutExtension < 6) {
            maxNornalNameWithoutExtension = 6;
        }
        if (originalFilenameWithoutExtension.length > maxNornalNameWithoutExtension) { // to cover with ellipsis
            const
                shortenedFilenameWithoutExtension =
                    originalFilenameWithoutExtension.substring(0, maxNornalNameWithoutExtension);

            return shortenedFilenameWithoutExtension + ellipsis + fileSuffixAndExtension;
        }

        return filename;
    };

const openImageEditorSaga = function* (): Generator<any, void, void> {
    yield takeLatest(
        OPEN_IMAGE_EDITOR_DIALOG,
        function* ({ payload: dialogProps }: Action<any>) {
            let language = yield* selectGen(({ epics }: AppState) => epics[languagesValueActionType].state.current);
            if (language === 'en_us' || language === 'en_gb') {
                language = 'en';
            } else if (language === 'nb') {
                language = 'no';
            }
            yield put(openDialog(dialogId, { ...dialogProps, language }));
        }
    );
};

const saveEditedImageSaga = function* (): Generator<any, void, void> {
    yield takeLatest(
        SAVE_EDITED_IMAGE,
        function* ({
            payload: {
                asset,
                blob,
                replaceWithEditedImageAction,
                extraPayload,
                fileNameSuffix = editedImageNameSuffix,
                forwardToSelectedComponent = true
            }
        }: Action<any>) {
            if (!replaceWithEditedImageAction) {
                throw new Error("No action for dispatching on edited image");
            }

            let fileInfo: AssetFileInfo = getAssetFileInfo(asset);
            yield put(loadResources(fileInfo.dir, true, IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS));

            let { webspaceFetchSuccess, webspaceFetchFailed } = yield* raceGen({
                webspaceFetchSuccess: take(IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS),
                webspaceFetchFailed: take(IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE)
            });

            if (webspaceFetchFailed) {
                const success = yield* takeSuccessFromApiActionGen(fcCreateWebSpaceFolder(FC_ONEWEBMEDIA_PATH));
                if (success) {
                    yield put(loadResources(fileInfo.dir, true, IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS));

                    const apiData = yield* raceGen({
                        webspaceFetchSuccess: take(IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS),
                        webspaceFetchFailed: take(IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE)
                    });
                    webspaceFetchSuccess = apiData.webspaceFetchSuccess;
                }
            }
            if (webspaceFetchSuccess && webspaceFetchSuccess.payload.entries) {
                const { entries } = webspaceFetchSuccess.payload;

                const
                    newImageFilePath = getNewFileName(entries, fileInfo, fileNameSuffix),
                    newFileName = newImageFilePath.substring(newImageFilePath.lastIndexOf('/') + 1),
                    displayFileName = getDisplayFileName(decodeWebspaceUri(newFileName), fileInfo.extn, fileNameSuffix);

                if (!forwardToSelectedComponent) {
                    yield put(updateImageEditorMessage(MessageCodes.SAVING_FILE_AS, newFileName));
                }

                const file = blobToFile(blob, newFileName);
                // try to upload new file into webspace
                // @ts-ignore
                yield put(uploadFromComputerApiAction(file, newImageFilePath));

                const { imageUploadSuccess } = yield* raceGen({
                    imageUploadSuccess: take(FC_COMPUTER_UPLOAD_SUCCESS),
                    imageUploadFailed: take(FC_COMPUTER_UPLOAD_FAILURE)
                });

                if (imageUploadSuccess && !forwardToSelectedComponent) {
                    yield put({ type: IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG, payload: displayFileName });
                    return;
                }
                if (imageUploadSuccess) {
                    const metadata = yield* getResourceMetadataSaga(newImageFilePath);
                    if (metadata) {
                        yield put(makeActionForwardToSelectedComponent({
                            type: replaceWithEditedImageAction,
                            payload: {
                                asset: {
                                    ...metadata,
                                    url: 'webspace:' + newImageFilePath,
                                    data: asset.data
                                },
                                ...(extraPayload || {})
                            }
                        }));
                        //yield put({ type: IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG, payload: displayFileName });
                        yield put({ type: IMAGE_EDITOR_SERIALIZED_SAVE_CLOSE_EDITOR });
                    } else {
                        yield put(updateImageEditorMessage(MessageCodes.GENERAL_ERROR)); // todo SAVE ERROR
                    }
                } else {
                    yield put(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));
                }
            } else {
                yield put(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));
            }
        }
    );
};

export default function* root(): any {
    yield all([
        fork(openImageEditorSaga),
        fork(saveEditedImageSaga)
    ]);
}
