import React from "react";
import { useSelector } from "react-redux";

import { OnlineShopOnboarding } from "./OnlineShopOnboarding";
import { selectOnlineShopStatus } from "../../../../common/selectors";
import { OnlineShopStats } from "./OnlineShopStats";
import { OnlineShopWidgetLoader } from "./OnlineShopWidgetLoader";
import { RenderSwitch } from "../../../Helpers/RenderWhen";
import { OnlineShopStatus } from "../../OnlineShop/types";
import { isOnlineShopCmpsNotAllowedBySubscriptionType } from "../../../../../../../wbtgen/src/components/TemplateSelector_DEPRECATED/view/utils";

export const OnlineShopWidget = () => {
    const onlineShopStatus = useSelector(selectOnlineShopStatus);
    const shopNotAllowed = isOnlineShopCmpsNotAllowedBySubscriptionType();

    return (
        <RenderSwitch>
            <RenderSwitch.Case when={shopNotAllowed} />

            <RenderSwitch.Case when={onlineShopStatus === null}>
                <OnlineShopWidgetLoader />
            </RenderSwitch.Case>

            <RenderSwitch.Case when={onlineShopStatus === OnlineShopStatus.NoShop}>
                <OnlineShopOnboarding />
            </RenderSwitch.Case>

            <RenderSwitch.Case when={onlineShopStatus === OnlineShopStatus.Active}>
                <OnlineShopStats />
            </RenderSwitch.Case>

            <RenderSwitch.Case default />
        </RenderSwitch>
    );
};
