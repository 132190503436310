import * as React from 'react';

export type HoverBoxContextFn = () => void;
export type HoverBoxContextValue = {
    onPreviewOver: HoverBoxContextFn,
    onPreviewOut: HoverBoxContextFn,
};

const defaultValue: HoverBoxContextValue = {
    onPreviewOver: () => {},
    onPreviewOut: () => {}
};


export const HoverBoxContext = React.createContext(defaultValue);
