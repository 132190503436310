/* eslint-disable max-len */
import React from 'react';
import { injectIntl, Msg } from "../../../../view/intl/index";
import { getAppConfig } from "../../../App/epics/appConfig/appConfig";
import { getBrandName } from '../../constants';
import styles from "../../paidShutterstock/components/PaidShutterstock.css";
import { ThirdPartyImageKind } from '../../unsplash/components/constants';

const appConfig = getAppConfig();

const ShutterStockTermsLink = () => (
    <a
        href="https://www.one.com/admin/image-buy-terms.do"
        className={styles.footerLink}
        target="_blank"
    >
        <Msg k="fm.dialog.tab.paidShutterstock.footerLink">Shutterstock’s terms of use</Msg>
    </a>
);

const UnsplashTermsLink = injectIntl(({ intl }) => (
    <a
        href={intl.msgJoint("msg: fm.dialog.tab.thirdPartyImageCollection.unsplash.footer.termsLinkUrl {https://unsplash.com/terms}")}
        className={styles.footerLink}
        target="_blank"
    >
        <Msg k="fm.dialog.tab.thirdPartyImageCollection.unsplash.footer.termsLink">Unsplash\'s terms</Msg>
    </a>
));

type Props = {
    freeImages?: boolean,
    kind?: string,
    freeVideos?: boolean
}

export const ShutterstockFooter = ({ freeImages = false, kind = '', freeVideos = false }: Props) => {
    const termsLink = kind === ThirdPartyImageKind.UNSPLASH ? <UnsplashTermsLink /> : <ShutterStockTermsLink />;

    if (freeVideos) {
        return (
            <Msg
                className={styles.footer}
                k="fm.dialog.tab.onecomVideos.footer.freeVideos"
                params={{ link: termsLink, brand: getBrandName(appConfig.partnerName) }}
            >{`These videos are provided by {brand}. By using them, you agree to comply with {link}.`}</Msg>
        );
    }

    if (kind === ThirdPartyImageKind.UNSPLASH) {
        return (
            <Msg
                className={styles.footer}
                k="fm.dialog.tab.thirdPartyImageCollection.footer.freeImages"
                params={{ link: termsLink }}
            >{`By clicking the \"Continue\" button, you declare that you accept Unsplash\'s Terms of Use. You get a simple non-exclusive license to use photos on your website for commercial purposes as well. Find out the exact content of your license here: {link}`}</Msg>
        );
    }

    return (
        freeImages ?
            <Msg
                className={styles.footer}
                k="fm.dialog.tab.shutterstock.footer.freeImages"
                params={{ link: termsLink }}
            >{`By using these free images, you agree to {link}.`}</Msg> :
            <Msg
                className={styles.footer}
                k="fm.dialog.tab.shutterstock.footer.paidImages"
                params={{ link: termsLink }}
            >{`By buying images, you agree to {link}.`}</Msg>
    );
};
