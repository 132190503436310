import { savePreferencesAction } from '../Preferences/actions';
import { bannerCloseButtonClickedAction } from "./actions";

import common from "../../../../../wbtgen/src/view/Theme/common.css";
import imageStyles from "../Images/imageStyle.css";
import { windowOpenUrlAction } from '../Window/actions';
import { isPartnerHostnet } from '../../common/config';
import AppConfig from '../../../../../wbtgen/src/utils/AppConfig';
import { getAppConfig } from "../../../../../wbtgen/src/components/App/epics/appConfig/appConfig";
import { makeKlikenUrl } from '../Pages/Analytics/utils';
import { AppState } from '../../common/types';
import { selectCurrentLanguage } from '../../common/selectors';

export type BannerConfig = {
    /**  banner unique id.  */
    id: string;
    /**  banner title.  */
    title?: string;
    /**  banner body content.  */
    description: string;
    /**  banner icon.  */
    icon: string;
    /**  banner background color */
    backgroundColor?: string;
    /**
     *  banner will take `position: sticky`, parent component might need
     *  some style adjustment to display the banner in `sticky` mode.
     */
    sticky?: boolean;
    /**
     *  if set to `true`, banner will not have a dismiss button.
     */
    persistent?: boolean;
    /**
     *  if set to `true`, banner will be displayed immediately (no delay or transition).
     *
     *  TODO: as of now it's only applicable to overlay banners.
     *  TODO: remove in favor of using the `delay` option (use `0` for no delay).
     */
    noDelay?: boolean;
    /**
     *  delay before adding the banner to the render tree;
     */
    delay?: number;
    /**
     *  if set to `true`, banner dismiss flag will be stored in sessionStorage,
     *  otherwise it'll be stored in localStorage.
     */
    sessionOnly?: boolean;
    closeAction?: (dispatch: Dispatch) => void;
    ctaText?: string;
    ctaAction?: (dispatch: Dispatch, state?: AppState) => void;
};

const oneTimeBannerCloseAction = (key: string, dispatch: Dispatch) => {
    dispatch(savePreferencesAction({ [key]: true }));
    dispatch(bannerCloseButtonClickedAction(key));
};

const appConfig = AppConfig(getAppConfig());
export const
    DashboardBannerIds = {
        // analytics widget in main page
        ANALYTICS_WIDGET_BANNER_PUBLISH_AFTER_DISABLED: "analytics-widget-banner-publish-after-disabled",
        ANALYTICS_WIDGET_BANNER_WAIT_AFTER_PUBLISH: "analytics-widget-banner-wait-after-publish",
        ANALYTICS_WIDGET_BANNER_PARTNER_SUBDOMAIN_DISABLED: "analytics-widget-banner-partner-subdomain-disabled",
        ANALYTICS_WIDGET_BANNER_SUSPENDED_ACCOUNTS_ERROR: "analytics-widget-banner-suspended-accounts-error",

        // analytics dashboard page
        ANALYTICS_DASHBOARD_BANNER_PUBLISH_AFTER_ENABLED: "analytics-dashboard-banner-publish-after-enabled",
        ANALYTICS_DASHBOARD_BANNER_PUBLISH_AFTER_DISABLED: "analytics-dashboard-banner-publish-after-disabled",
        ANALYTICS_DASHBOARD_BANNER_WAIT_AFTER_PUBLISH: "analytics-dashboard-banner-wait-after-publish",
        ANALYTICS_DASHBOARD_BANNER_SUSPENDED_ACCOUNTS_ERROR: "analytics-dashboard-banner-suspended-accounts-error",

        // Marketgoo
        MARKETGOO_BANNER_REMEMBER_TO_PUBLISH: "marketgoo-banner-remember-to-publish",
        MARKETGOO_BANNER_RESCAN_FAILED: "marketgoo-banner-rescan-failed",
        MARKETGOO_BANNER_DONT_SEE_INFORMATION: "marketgoo-dont-see-information",

        // Messenger Chat
        MESSENGER_CHAT_BANNER_LOADING_SDK_FAILED: "messenger-chat-sdk-loading-failed",
        MESSENGER_CHAT_BANNER_FEATURE_DISABLED: "messenger-chat-feature-disabled",

        //3rd party apps
        THIRDPARTYAPP_DASHBOARD_BANNER_PARTNER_SUBDOMAIN_DISABLED: "thirdpartyapp-dashboard-banner-partner-subdomain-disabled",
    },

    DashboardBannersConfig: Record<string, BannerConfig> = {
        [DashboardBannerIds.ANALYTICS_WIDGET_BANNER_PUBLISH_AFTER_DISABLED]: {
            id: DashboardBannerIds.ANALYTICS_WIDGET_BANNER_PUBLISH_AFTER_DISABLED,
            title: "msg: analytics.publishAfterDisabled.bannerTitle {Remember to publish}",
            description: "msg: analytics.publishAfterDisabled.bannerDescription {You’ll need to publish to remove tracking from your site.}",
            icon: imageStyles.analyticsBannerIcon,
            persistent: true,
            noDelay: true
        },
        [DashboardBannerIds.ANALYTICS_WIDGET_BANNER_WAIT_AFTER_PUBLISH]: {
            id: DashboardBannerIds.ANALYTICS_WIDGET_BANNER_WAIT_AFTER_PUBLISH,
            title: "msg: analyticsTab.banner.title {Don’t see any analytics?}",
            description: "msg: analyticsPreview.banner.description {After publishing it can take a little while for them to show up but check back again soon.}",
            icon: imageStyles.analyticsBannerIcon,
        },
        [DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_PUBLISH_AFTER_ENABLED]: {
            id: DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_PUBLISH_AFTER_ENABLED,
            title: "msg: analyticsTab.banner.title {Don’t see any analytics?}",
            description: "msg: analyticsTab.banner.description {You’ll need to publish your site for analytics to show up. After publishing, it can take a little while for them to show up but check back again soon.}",
            icon: imageStyles.analyticsBannerIcon,
            noDelay: true,
            persistent: true,
        },
        [DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_PUBLISH_AFTER_DISABLED]: {
            id: DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_PUBLISH_AFTER_DISABLED,
            title: "msg: analytics.publishAfterDisabled.bannerTitle {Remember to publish}",
            description: "msg: analytics.publishAfterDisabled.bannerDescription {You’ll need to publish to remove tracking from your site.}",
            icon: imageStyles.analyticsBannerIcon,
            persistent: true,
            noDelay: true,
        },
        [DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_WAIT_AFTER_PUBLISH]: {
            id: DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_WAIT_AFTER_PUBLISH,
            title: "msg: analyticsTab.banner.title {Don’t see any analytics?}",
            description: "msg: analyticsPreview.banner.description {After publishing it can take a little while for them to show up but check back again soon.}",
            icon: imageStyles.analyticsBannerIcon,
            noDelay: true,
        },
        [DashboardBannerIds.MARKETGOO_BANNER_REMEMBER_TO_PUBLISH]: {
            id: DashboardBannerIds.MARKETGOO_BANNER_REMEMBER_TO_PUBLISH,
            title: "msg: seoScan.tip.rememberToPublish.title {Remember to publish}",
            description: "msg: seoScan.tip.rememberToPublish.description {To update your score and task list, you need to publish your site again. This takes some time, so publish once you have completed all your tasks for today}",
            icon: imageStyles.publish,
            sticky: true,
            sessionOnly: true,
        },
        [DashboardBannerIds.MARKETGOO_BANNER_RESCAN_FAILED]: {
            id: DashboardBannerIds.MARKETGOO_BANNER_RESCAN_FAILED,
            title: "msg: seoScan.tip.rescanFailed.title {Rescan failed}",
            description: "msg: seoScan.tip.rescanFailed.description {Please refresh the page to try again.}",
            icon: imageStyles.errorSmall,
            sticky: true,
            persistent: true,
        },
        [DashboardBannerIds.MARKETGOO_BANNER_DONT_SEE_INFORMATION]: {
            id: DashboardBannerIds.MARKETGOO_BANNER_DONT_SEE_INFORMATION,
            backgroundColor: common.colorWizardPink1_axE8,
            title: "msg: seoScan.tip.doNotSeeAnyInformation.title {Don’t see any information?}",
            description: "msg: seoScan.tip.doNotSeeAnyInformation.description.v2 {It takes a little while for the SEO scan to run. Please check back again soon.}",
            icon: imageStyles.infoLarge,
            sticky: true,
            sessionOnly: true,
            closeAction: (dispatch: Dispatch) => {
                oneTimeBannerCloseAction(DashboardBannerIds.MARKETGOO_BANNER_DONT_SEE_INFORMATION, dispatch);
            }
        },
        [DashboardBannerIds.MESSENGER_CHAT_BANNER_LOADING_SDK_FAILED]: {
            id: DashboardBannerIds.MESSENGER_CHAT_BANNER_LOADING_SDK_FAILED,
            title: "msg: messengerChat.banner.sdkLoadingFailed.title {Login to Facebook blocked}",
            description: "msg: messengerChat.banner.sdkLoadingFailed.description {If you are using a privacy extension on your browser, this may be blocking the window to sign in to Facebook. Disable your privacy extension and try again.}",
            icon: imageStyles.infoLarge,
            sticky: true,
            sessionOnly: true,
        },
        [DashboardBannerIds.MESSENGER_CHAT_BANNER_FEATURE_DISABLED]: {
            id: DashboardBannerIds.MESSENGER_CHAT_BANNER_FEATURE_DISABLED,
            title: "msg: messengerChat.banner.sdkLoadingFailed.title {Login to Facebook blocked}",
            description: "msg: facebook.accountDisableMsg.fbChat {We’re sorry, but this feature is currently not available. We're working on resolving our connection with Facebook. Please come back later and try again. We apologise for the inconvenience.}",
            icon: imageStyles.infoLarge,
            sticky: true,
            persistent: true,
        },
        [DashboardBannerIds.THIRDPARTYAPP_DASHBOARD_BANNER_PARTNER_SUBDOMAIN_DISABLED]: {
            id: DashboardBannerIds.THIRDPARTYAPP_DASHBOARD_BANNER_PARTNER_SUBDOMAIN_DISABLED,
            title: "msg: thirdpartyapp.featureNotAvailable.title {Feature not available yet}",
            description: "msg: thirdpartyapp.featureNotAvailable.Msg {You\'ll need to connect your website to a domain to access this feature.}",
            icon: imageStyles.infoLarge,
            noDelay: true,
            persistent: true,
            ctaText: isPartnerHostnet ? "msg: common.learnMore {Learn more}" : "msg: common.seeNextSteps {See next steps}",
            ctaAction: (dispatch: Dispatch) => {
                dispatch(windowOpenUrlAction(appConfig.get('oneWeb.thirdPartyApps.disableForSubdomainLearnMoreUrl')));
            }
        },
        [DashboardBannerIds.ANALYTICS_WIDGET_BANNER_PARTNER_SUBDOMAIN_DISABLED]: {
            id: DashboardBannerIds.ANALYTICS_WIDGET_BANNER_PARTNER_SUBDOMAIN_DISABLED,
            title: "msg: analytics.featureNotAvailable.title {Analytics not available yet}",
            description: "msg: thirdpartyapp.featureNotAvailable.Msg {You\'ll need to connect your website to a domain to access this feature.}",
            icon: imageStyles.infoLarge,
            noDelay: true,
            persistent: true
        },
        [DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_SUSPENDED_ACCOUNTS_ERROR]: {
            id: DashboardBannerIds.ANALYTICS_DASHBOARD_BANNER_SUSPENDED_ACCOUNTS_ERROR,
            description: "msg: analytics.suspendedAccountsError.bannerDescription {It seems that you have deleted your Kliken personal data and therefore analytics cannot be activated. Please contact Kliken to recover your data. Data recovery is possible within 30 days of deletion.}",
            icon: imageStyles.infoLarge,
            noDelay: true,
            persistent: true,
            ctaText: "msg: analytics.suspendedAccountsError.bannerAction {Contact Kliken}",
            ctaAction: (dispatch: Dispatch, state?: AppState) => {
                dispatch(windowOpenUrlAction(makeKlikenUrl(selectCurrentLanguage(state!), 'contact-us.html')));
            }
        },
        [DashboardBannerIds.ANALYTICS_WIDGET_BANNER_SUSPENDED_ACCOUNTS_ERROR]: {
            id: DashboardBannerIds.ANALYTICS_WIDGET_BANNER_SUSPENDED_ACCOUNTS_ERROR,
            description: "msg: analytics.suspendedAccountsError.bannerDescription {It seems that you have deleted your Kliken personal data and therefore analytics cannot be activated. Please contact Kliken to recover your data. Data recovery is possible within 30 days of deletion.}",
            icon: imageStyles.infoLarge,
            noDelay: true,
            persistent: true
        },
    };
