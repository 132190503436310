import { getDAL } from "../../../dal";
import { isPartnerOneCom } from "./utils";

// Eg: "wsb-blabla.plesk-hosting-testen21.nl"
const isHostnetSubdomain = () => {
    const domainName = getDAL().getDomain();
    const parts = domainName.split(".");
    return parts.length === 3 && /^wsb-/.test(parts[0]);
};

// Eg: "w1082734.checkdomainwsb.one"
const isCheckDomainSubdomain = () => {
    const domainName = getDAL().getDomain();
    const parts = domainName.split(".");
    return parts.length === 3 && parts[0] && /checkdomainwsb.one$/.test(domainName);
};

const isPartnerSubdomain = () => !isPartnerOneCom() && (isHostnetSubdomain() || isCheckDomainSubdomain());

export {
    isPartnerSubdomain
};
