import type { DataDesignT } from "../../../../dal/model/DataDesign";
import { setCookie, getCookie, unsetCookie, ONE_COM_BASE_COOKIE_DOMAIN } from "../../../services/cookie";

type Data = { template: DataDesignT, isLoggedIn: boolean, expires: string };

const
    COOKIE_NAME = 'one.com.selectedTemplate',
    TEMPLATE_ACCOUNT_COOKIE_NAME = 'public.selectedTemplate',
    TRIAL_ORIGIN_COOKIE_NAME = 'public.trialOrigin',
    COOKIE_DURATION = 10; // minutes

export {
    COOKIE_NAME as SELECTED_PUBLIC_TEMPLATE_COOKIE_NAME,
    COOKIE_DURATION as SELECTED_PUBLIC_TEMPLATE_COOKIE_DURATION,
    TEMPLATE_ACCOUNT_COOKIE_NAME as SELECTED_PUBLIC_TEMPLATE_ACCOUNT_COOKIE_NAME
};

/*** Main ***/

export const setSelectedPublicTemplate = (template: DataDesignT) => {
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + COOKIE_DURATION);

    const cookieValue = encodeCookieValue({ template, isLoggedIn: false, expires: expires.toString() });

    doSetCookie(cookieValue, { expires });
};

export const setSelectedPublicTemplateAccountName = (accountName: string) => {
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + COOKIE_DURATION);

    const cookieValue = encodeCookieValue(accountName);

    setCookie(TEMPLATE_ACCOUNT_COOKIE_NAME, cookieValue, { expires });
};

export const setTrialOrigin = (origin: string) => {
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + COOKIE_DURATION);

    const cookieValue = encodeCookieValue(origin);

    setCookie(TRIAL_ORIGIN_COOKIE_NAME, cookieValue, { expires });
};

export const readSelectedPublicTemplate = (): Data | null => {
    let res = null;

    const cookieVal = getCookie(COOKIE_NAME);

    if (cookieVal) {
        try {
            res = JSON.parse(cookieVal);
        } catch (_: any) {
            // will return null
        }
    }

    return res;
};

export const readSelectedPublicTemplateAccountName = (): string | null => {
    let res: string | null = null;

    let cookieVal = getCookie(TEMPLATE_ACCOUNT_COOKIE_NAME);

    if (cookieVal) {
        try {
            res = JSON.parse(cookieVal);
        } catch (_: any) {
            // will return null
            cookieVal = cookieVal.trim();
            if (typeof cookieVal === 'string' && cookieVal.startsWith('design-')) {
                res = cookieVal;
            }
        }
    }

    return res;
};

export const trySelectedPublicTemplateLogIn = (): boolean => {
    const data = readSelectedPublicTemplate();

    if (!data || data.isLoggedIn) {
        return false;
    }

    const
        { template, expires } = data,
        nextCookieVal = encodeCookieValue({ template, isLoggedIn: true, expires });

    doSetCookie(nextCookieVal, { expires: new Date(expires) });

    return true;
};

export const readTrialOrigin = (): string | null => {
    let res = null;
    const cookieVal = getCookie(TRIAL_ORIGIN_COOKIE_NAME);
    if (cookieVal) {
        try { res = JSON.parse(cookieVal); } catch (_: any) { /*will return null */ }
    }
    return res;
};

export const dropSelectedPubicTemplate = () => {
    unsetCookie(COOKIE_NAME, { domain: ONE_COM_BASE_COOKIE_DOMAIN });
    unsetCookie(TEMPLATE_ACCOUNT_COOKIE_NAME, { domain: ONE_COM_BASE_COOKIE_DOMAIN });
};

export const dropTrialOrigin = () => {
    unsetCookie(TRIAL_ORIGIN_COOKIE_NAME, { domain: ONE_COM_BASE_COOKIE_DOMAIN });
};

/*** Private ***/

function encodeCookieValue(data) { return JSON.stringify(data); }

type CookieOptions = {
    expires?: Date;
    path?: string;
    // Add other options as needed
  };

function doSetCookie(value, options?: CookieOptions) {
    const args: [string, CookieOptions?] = options ? [value, options] : [value];
    setCookie(COOKIE_NAME, ...args);
}
