import React from 'react';
import ShiftBarTemplate from "./template";
import styles, { shiftBarOpacity, shiftLineThickness } from '../../../App.css';
import type { FactoryPropsSelection } from './flowTypes';
import { isShiftBarTop } from '../../../../utils/handle/index';
import type { BBox, Handles } from '../../../../redux/modules/children/workspace/flowTypes';
import { SelectionFrameBorderWidth } from '../../../../constants/app';
import { getShiftBarTopSelectionLeft } from '../../../../utils/handle/shiftBar';

type Props = {
    state: {
        bBox: BBox,
        isBorderVisible: boolean,
        workspaceBBox: BBox,
        topLineIsVisible: boolean,
        selectionShiftBarVisible: boolean,
        bottomLineIsVisible: boolean,
        topMostHandleKind: string | null
    },
    handles: Handles
}


const SpaceAdjusterSelection = props => {
    const
        { kind, bBox, isBorderVisible, lineIsVisible, selectionShiftBarVisible, handles, isHover } = props;
    const shiftPointBarStyle = {
            top: isShiftBarTop(kind) ? bBox.top - shiftLineThickness : bBox.bottom,
            // Since frame decoration appears outside of component, 1px margin must be provided
            left: bBox.left - SelectionFrameBorderWidth,
            width: bBox.right - bBox.left + (SelectionFrameBorderWidth * 2),
            opacity: lineIsVisible ? shiftBarOpacity : 0
        },
        currentShiftBarHandles = handles.filter(handle => handle.kind === kind);

    return (
        <div className={styles.shiftPointContainer} style={{ opacity: isBorderVisible ? 1 : 0 }}>
            <div
                className={styles.shiftPointBar}
                style={shiftPointBarStyle}
            />
            {selectionShiftBarVisible && Boolean(currentShiftBarHandles.length) && <ShiftBarTemplate
                top={currentShiftBarHandles[0].bBox.top}
                left={getShiftBarTopSelectionLeft(bBox)}
                kind={kind}
                handles={currentShiftBarHandles}
                isHover={isHover}
                isActive={lineIsVisible}
            />}
        </div>
    );
};

export default function ({ kind }: FactoryPropsSelection) {
    return ({ state: { bBox, isBorderVisible, workspaceBBox, topLineIsVisible,
        bottomLineIsVisible, selectionShiftBarVisible, topMostHandleKind }, handles }: Props) => {
        const lineIsVisible = ((isShiftBarTop(kind) && topLineIsVisible)
            || (!isShiftBarTop(kind) && bottomLineIsVisible));

        return (
            <SpaceAdjusterSelection
                isHover={topMostHandleKind === kind}
                kind={kind}
                bBox={bBox}
                isBorderVisible={isBorderVisible}
                workspaceBBox={workspaceBBox}
                lineIsVisible={lineIsVisible}
                selectionShiftBarVisible={selectionShiftBarVisible}
                handles={handles}
            />
        );
    };
}
