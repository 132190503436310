// @ts-nocheck

/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";

import { TabDialogWithFullLeftPanel, Tab } from '../../../../view/common/dialogs/TabDialog/TabDialogWithFullLeftPanel';
import styles from './SiteSettingsDialog.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import { default as SiteSettingsFooter } from './SiteSettingsFooter';
import { SiteSettingsTabName, SiteSettingsDialogId } from '../constants';
import { injectIntl, Intl } from "../../../../view/intl/index";
import SecurityTab from "../../publishConfig/SecurityTab";
import SeoTab from '../../seoGlobalData/SeoTab';
import SocialShareTab from '../../socialShareGlobalData/SocialShareTab';
import TrackingTab from '../../trackingGlobalData/TrackingTab';
import { CLOSE_DIALOG_BY_ID_ONLY_IF_TOP_MOST } from "../../../../redux/modules/actionTypes";
import { GeneralInfoTab } from '../../General/GeneralInfoTab';
import { BusinessListingTab } from '../../BusinessListing/BusinessListingTab';
// import { ls as LocalStorage } from "../../../../utils/localStorage";
// import { AllowMessengerChatWidgetKey } from "../../../oneweb/FacebookChat/constants/index";
import { isWsbFeatureEnabled } from "../../../ComponentTierManager/utils";
import { WsbFeature } from "../../../../../../server/shared/WsbFeature";
import getFeatureManager from '../../../../getFeatureManager';

type Props = {
    activeTabKey?: string,
    activateInputWithId?: string,
    activateDivWithId?: string,
    intl: Intl,
    dispatch: Dispatch,
}

class SiteSettingsDialog extends React.Component<Props> {
    render() {
        const { activeTabKey, activateInputWithId, activateDivWithId, intl } = this.props;
        const seoTitle = intl.msgJoint('msg: seoLong {SEO (Search Engine Optimization)}');
        // allowMessengerChatWidget = LocalStorage.get(AllowMessengerChatWidgetKey),
        const showSeoTab = getFeatureManager().isSeoTabShown();
        const showSocialShareTab = getFeatureManager().isSocialShareTabShown();
        const showTrackingTab = getFeatureManager().isTrackingTabShown();
        const showSecurityTab = getFeatureManager().isSecurityTabShown();
        const showBusinessListingTab = getFeatureManager().isBusinessListingTabShown();

        return (
            <TabDialogWithFullLeftPanel
                stripType={StripTypes.NONE}
                dispatch={this.props.dispatch}
                mctaHandler={() => {
                    this.props.dispatch({
                        type: CLOSE_DIALOG_BY_ID_ONLY_IF_TOP_MOST, payload: SiteSettingsDialogId
                    });
                }}
                onClose={() => this.props.dispatch({
                    type: CLOSE_DIALOG_BY_ID_ONLY_IF_TOP_MOST, payload: SiteSettingsDialogId
                })}
                mctaText="msg: common.done {Done}"
                primaryBtnClass={styles.mctaStyle}
                activeTabKey={activeTabKey || SiteSettingsTabName.GENERAL}
                containerClassName={cx(styles.container, styles.containerFlexDirRow)}
                tabBodyCustomStyle={styles.tabBody}
                tabHeadingCustomStyle={styles.tabHeading}
                panelHeaderText="msg: component.common.Settings {Settings}"
                panelStyle={styles.panelStyle}
                panelItemStyle={styles.panelItem}
                panelItemActiveStyle={styles.panelItemActive}
                footer={<SiteSettingsFooter />}
                footerStyles={cx(styles.footerPanel, styles.footerStyles)}
            >
                <Tab
                    id={SiteSettingsTabName.GENERAL}
                    tabIcon={cx(styles.tabIconCommon, styles.generalIcon)}
                    label="msg: common.general {General}"
                    title="msg: common.general {General}"
                    subTitleContent={intl.msgJoint("msg: generalInfo.header.label {This is the info used for your components, mobile header and templates.}")} // eslint-disable-line max-len
                    subTitleClass={styles.dialogSubTitleClass}
                    className={styles.commonTabClass}
                >
                    <GeneralInfoTab activateInputWithId={activateInputWithId} activateDivWithId={activateDivWithId} />
                </Tab>
                {
                    showSeoTab &&
                    <Tab
                        id={SiteSettingsTabName.SEO}
                        tabIcon={cx(styles.tabIconCommon, styles.seoIcon)}
                        label="msg: common.seo {SEO}"
                        title={seoTitle}
                        subTitleContent={intl.msgJoint("msg: seoTab.header.label {SEO is about optimizing your site, so it easily can be found and ranked by search engines.}")} // eslint-disable-line max-len
                        subTitleClass={styles.dialogSubTitleClass}
                    >
                        <SeoTab />
                    </Tab>
                }
                {
                    showSocialShareTab &&
                    <Tab
                        id={SiteSettingsTabName.SOCIAL_SHARE}
                        tabIcon={cx(styles.tabIconCommon, styles.socialShareIcon)}
                        label="msg: common.socialShare {Social share}"
                        title="msg: common.socialShare {Social share}"
                        subTitleContent={intl.msgJoint("msg: socialShare.header.label {Add the image you want to display when your site is shared on social networks.}")} // eslint-disable-line max-len
                        subTitleClass={styles.dialogSubTitleClass}
                        className={styles.commonTabClass}
                    >
                        <SocialShareTab />
                    </Tab>
                }
                {
                    showTrackingTab &&
                    <Tab
                        id={SiteSettingsTabName.TRACKING}
                        tabIcon={cx(styles.tabIconCommon, styles.trackingIcon)}
                        label="msg: common.tracking {Tracking}"
                        title="msg: common.tracking {Tracking}"
                        subTitleContent={intl.msgJoint("msg: trackingTab.header.label {Add third-party tools to track and analyze your website.}")} // eslint-disable-line max-len
                        subTitleClass={styles.dialogSubTitleClass}
                        className={styles.commonTabClass}
                    >
                        <TrackingTab />
                    </Tab>
                }
                {
                    showSecurityTab &&
                    <Tab
                        id={SiteSettingsTabName.SECURITY}
                        tabIcon={cx(styles.tabIconCommon, styles.securityIcon)}
                        label="msg: common.security {Security}"
                        title="msg: common.security {Security}"
                        subTitleContent={intl.msgJoint("msg: securityTab.header.label {These settings keep your website secure and improve the way your address appears in the browser.}")} // eslint-disable-line max-len
                        subTitleClass={styles.dialogSubTitleClass}
                        className={styles.commonTabClass}
                    >
                        <SecurityTab />
                    </Tab>
                }
                {
                    showBusinessListingTab &&
                    <Tab
                        id={SiteSettingsTabName.BUSINESS_LISTINGS}
                        tabIcon={cx(styles.tabIconCommon, styles.businessListingsIcon)}
                        label="msg: common.businessListings.label {Business listing}"
                        title="msg: common.businessListings.label {Business listing}"
                        subTitleContent={intl.msgJoint("msg: siteSettings.businessListings.introText {Enhance online visibility and overall growth of your business}")}
                        subTitleClass={styles.dialogSubTitleClass}
                        className={styles.commonTabClass}
                    >
                        <BusinessListingTab dispatch={this.props.dispatch} />
                    </Tab>
                }
            </TabDialogWithFullLeftPanel>
        );
    }
}

export default connect()(injectIntl(SiteSettingsDialog));
