export const ONLINE_SHOP_DASHBOARD_DIALOG_ID = "ONLINE_SHOP_DASHBOARD_DIALOG_ID";

export const ONLINE_SHOP_DASHBOARD_LOAD_TIMEOUT = 30 * 1000;
export const ONLINE_SHOP_DASHBOARD_DIALOG_Z_INDEX = 1000000;

export const WebshopAdminIframeId = "WebshopAdmin-frame";

export enum OnlineShopMessageTypes {
    // Webshop
    WEBSHOP_RELOADING = "WEBSHOP_RELOADING",
    WEBSHOP_LOADING_COMPLETE = "WEBSHOP_LOADING_COMPLETE",
    WEBSHOP_ROUTE_CHANGE = "WEBSHOP_ROUTE_CHANGE",
    WEBSHOP_CLOSE = "WEBSHOP_CLOSE",
    WEBSHOP_LOGOUT = "WEBSHOP_LOGOUT",
    WEBSHOP_OPEN_WSB_EDITOR = "WEBSHOP_OPEN_WSB_EDITOR",
    UPGRADED_TO_ECOMMERCE = "UPGRADED_TO_ECOMMERCE",
    WEBSHOP_REQUESTING_CLOSE_WSB_TAB = "WEBSHOP_REQUESTING_CLOSE_WSB_TAB",
    WEBSHOP_SET_WSB_TABID = "WEBSHOP_SET_WSB_TABID",
    WEBSHOP_REQUESTING_WSB_PRIVACY_POLICY = "WEBSHOP_REQUESTING_WSB_PRIVACY_POLICY",
    // WSB
    WSB_CLOSE_REQUEST = "WSB_CLOSE_REQUEST",
    WSB_ROUTE_CHANGE_REQUEST = "WSB_ROUTE_CHANGE_REQUEST"
}

export const
    ONLINE_SHOP_DASHBOARD_LOADING_START_ACTION = "ONLINE_SHOP_DASHBOARD_LOADING_START_ACTION",
    onlineShopDashboardLoadingStartAction = () => ({ type: ONLINE_SHOP_DASHBOARD_LOADING_START_ACTION }),

    ONLINE_SHOP_DASHBOARD_LOADED_ACTION = "ONLINE_SHOP_DASHBOARD_LOADED_ACTION",
    onlineShopDashboardLoadedAction = () => ({ type: ONLINE_SHOP_DASHBOARD_LOADED_ACTION }),

    ONLINE_SHOP_DASHBOARD_LOADING_ERROR_ACTION = "ONLINE_SHOP_DASHBOARD_LOADING_ERROR_ACTION",
    onlineShopDashboardLoadingErrorAction = () => ({ type: ONLINE_SHOP_DASHBOARD_LOADING_ERROR_ACTION });
