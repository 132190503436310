import { evolve } from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { when, receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import { getAppConfig } from "./appConfig";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import AppConfig from "../../../../utils/AppConfig";
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import { PartnerName, partnerIsHostnet } from "../../../../../../server/shared/partners/PartnerName";
import { WORKSPACE_READY } from '../../../Workspace/actionTypes';
import { DOMAIN_IS_ONEHOPS_CLIENT_SUCCESS, DOMAIN_IS_ONEHOPS_CLIENT_FAILURE, domainIsOneHopClient } from './actions';

const whenPartnerIsHostnet = when(
    valueActionType,
    ({ payload: appConfig }) => partnerIsHostnet(appConfig.partnerName)
);

const appConfigEpic = makeEpic({
    valueActionType,
    defaultState: getAppConfig(),
    updaters: [
        {
            // TODO remove in WBTGEN-4990
            conditions: [ReceiveOnlyComponentsMap, "EPIC_CRASH"],
            reducer: () => {
                throw new Error(`WBTGEN epic crash`);
            }
        },
        {
            conditions: [
                WORKSPACE_READY,
                receiveOnly(whenPartnerIsHostnet),
            ],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: domainIsOneHopClient(),
            })
        },
        {
            conditions: [
                DOMAIN_IS_ONEHOPS_CLIENT_SUCCESS,
                receiveOnly(whenPartnerIsHostnet),
            ],
            reducer: ({ state, values }) => {
                const [response] = values,
                    { isOneHopClient } = response;

                return {
                    state: evolve({
                        oneWeb: {
                            contactForm: (contactFormConfig) => ({
                                ...contactFormConfig,
                                isOneHopClient,
                            })
                        }
                    }, state)
                };
            }
        },
        {
            conditions: [
                DOMAIN_IS_ONEHOPS_CLIENT_FAILURE,
                receiveOnly(whenPartnerIsHostnet),
            ],
            reducer: ({ state }) => {
                return {
                    state: evolve({
                        oneWeb: {
                            contactForm: (contactFormConfig) => ({
                                ...contactFormConfig,
                                isOneHopClient: null,
                            })
                        }
                    }, state)
                };
            }
        },
    ]
});

const contactFormAllowArbitraryEmailReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => AppConfig(appConfig).get("oneWeb.contactForm.allowArbitraryEmail")
);

const withSSLReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => AppConfig(appConfig).get("oneWeb.publish.withSSL")
);

const isOneComPartnerReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => AppConfig(appConfig).get("partnerName") === PartnerName.ONECOM
);

const isHostnetPartnerReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => partnerIsHostnet(appConfig.partnerName)
);

export {
    appConfigEpic as default,
    withSSLReducer,
    contactFormAllowArbitraryEmailReducer,
    isOneComPartnerReducer,
    isHostnetPartnerReducer,
};
