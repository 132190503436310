import { $Values } from "utility-types";
import React from 'react';
import { connect } from "react-redux";
import cx from 'classnames';
import { pathOr } from 'ramda';

import styles from './FileChooserHeader.css';
import { ViewModes, FmGridSortOptions } from "../constants";
import { FC_TOGGLE_VIEW_MODE } from "../../../../redux/modules/children/fileChooser/actionTypes";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { currentPathSelector, fmGridSortOptionSel } from "../../../../redux/modules/children/fileChooser/selectors";
import { BreadcrumbsCom } from './BreadcrumbsCom';
import Msg from '../../../intl/Msg';
import { buildFileManagerUrl } from "../utils";
import AppConfig from "../../../../utils/AppConfig";
import { getDAL } from "../../../../../dal/index";
import { getCookie } from "../../../../services/cookie";
import getLogin from "../../../../components/App/epics/auth/utils/getLogin";
import { AUTH_COOKIE_KEY } from "../../../../components/App/epics/auth/constants";
import type { FileChooserViewMode } from "../flowTypes";
import type { AppConfigType } from "../../../../components/App/flowTypes";
import { injectIntl, Intl } from "../../../intl/index";
import { fcSortAction } from '../../../../redux/modules/children/fileChooser/actionCreators/index';
import { SortColumns, SortDirections } from '../../../../redux/modules/children/fileChooser/sortFunctions';
import { FmComboboxCom } from '../../../../components/FileManager/combobox/FmComboboxCom';
import { FmGridSortMessages } from './messages';
import type { FmComboboxOption } from '../../../../components/FileManager/combobox/FmComboboxCom';
import { TooltipPosition } from '../../../../components/presentational/Tooltip/constants';
import { DemoTip } from '../../../../../demo/modules/tip/DemoTip';
import { isDemoSubscriptionTypeAppSel } from "../../../../../demo/modules/selectors";
import { getAppConfig } from "../../../../components/App/epics/appConfig/appConfig";
import getFeatureManager from "../../../../getFeatureManager";

const configHideGoToFileManager = !getFeatureManager().isFileChooserShown();

type PropTypes = {
    viewMode: FileChooserViewMode,
    activeViewMode: null | undefined | string,
    currentPath: string,
    gridSortOption: null | undefined | string,
    intl: Intl,
    toggleViewMode: (activeMode: string) => void,
    sortAction: (column: $Values<typeof SortColumns>, direction: $Values<typeof SortDirections>) => void,
    appConfig: AppConfigType,
    isDemo: boolean,
    isFreeOneComVideoFileChooser?: boolean,
    className?: string,
}

class FcBreadCrumbsPaneClass extends React.Component<PropTypes> {
    gridSortOptions: Array<FmComboboxOption>;

    constructor(props) {
        super(props);
        this.gridSortOptions = FmGridSortOptions.map(({ label, value, column, direction }) => ({
            label,
            value,
            onClick: () => props.sortAction(column, direction),
        }));
    }

    renderGridSortBox() {
        const
            { activeViewMode, gridSortOption, intl, isFreeOneComVideoFileChooser = false } = this.props,
            placeholder = gridSortOption
                ? intl.msgJointFormat(
                    '%m %m',
                    [FmGridSortMessages.PREFIX, FmGridSortMessages[gridSortOption]]
                )
                : FmGridSortMessages.PREFIX;

        if (activeViewMode !== ViewModes.GRID) return null;

        return (
            <FmComboboxCom
                isFreeOneComVideoFileChooser={isFreeOneComVideoFileChooser}
                options={this.gridSortOptions}
                className={styles.gridSort}
                placeholder={placeholder}
                selectedValue={gridSortOption}
            />
        );
    }

    renderViewModeToggle() {
        const { viewMode, activeViewMode, toggleViewMode, intl } = this.props;

        return viewMode === ViewModes.COMBINED
            ? <div className={styles.viewMode}>
                <span
                    className={cx(styles.gridIcn, { [styles.viewModeActive]: activeViewMode === ViewModes.GRID })}
                    onClick={() => toggleViewMode(ViewModes.GRID)}
                    data-title={intl.msg('fc.gridToggle.title', 'Show items as thumbnails')}
                />
                <span
                    className={cx(styles.listIcn, { [styles.viewModeActive]: activeViewMode === ViewModes.LIST })}
                    onClick={() => toggleViewMode(ViewModes.LIST)}
                    data-title={intl.msg('fc.listToggle.title', 'Show items in a list')}
                />
            </div>
            : null;
    }

    gotoFileManager(e: any) {
        e.preventDefault();

        const
            { currentPath, appConfig } = this.props,
            fmConfig = AppConfig(appConfig).getOptional('oneWeb.filemanager'),
            login = getLogin(getCookie(AUTH_COOKIE_KEY)), // TODO: this should be obtained from auth middleware, but only email should be requested, whole auth cookie should never passed via actions, due to actions are tracked and may be passed to crash report system
            params = {
                currentPath,
                loginUrl: fmConfig.getOptional('loginUrl'),
                loginTarget: fmConfig.getOptional('loginTarget'),
                targetDomain: getDAL().getDomain(), // TODO: Dal will be moved to epic most likely
                mailId: login && login.email
            };

        const url = buildFileManagerUrl(params);
        window.open(url);
    }

    render() {
        const { className, activeViewMode, isDemo, isFreeOneComVideoFileChooser } = this.props,
            showGoToFileManager = !configHideGoToFileManager;

        return (
            <div className={cx(styles.bottomPane, className)}>
                { /* @ts-ignore */ }
                {!isFreeOneComVideoFileChooser && <BreadcrumbsCom activeViewMode={activeViewMode} isDemo={isDemo} />}
                {!isFreeOneComVideoFileChooser && this.renderGridSortBox()}
                {this.renderViewModeToggle()}
                {/* @ts-ignore */}
                {!isFreeOneComVideoFileChooser && showGoToFileManager && (<DemoTip position={TooltipPosition.BOTTOM}>
                    <a className={styles.gotoFileManagerUrl} onClick={this.gotoFileManager.bind(this)}>
                        <Msg k="fc.gotoFileManager">Go to File Manager</Msg>
                    </a>
                </DemoTip>)}
            </div>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    activeViewMode: appState.fileChooser.activeViewMode,
    currentPath: currentPathSelector(appState.fileChooser),
    appConfig: appState.fileChooser.appConfig,
    gridSortOption: fmGridSortOptionSel(appState.fileChooser),
    isDemo: isDemoSubscriptionTypeAppSel(appState),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleViewMode: activeMode => dispatch({ type: FC_TOGGLE_VIEW_MODE, payload: { activeMode } }),
    sortAction: (column, direction) => dispatch(fcSortAction({ column, direction })),
});

export const FcBreadCrumbsPaneCom = connect(mapStateToProps, mapDispatchToProps)(injectIntl(FcBreadCrumbsPaneClass));
