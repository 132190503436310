import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import { connect } from "react-redux";

import { useIntl } from '../../../../../../../../wbtgen/src/view/intl';

import { makeKlikenUrl } from "../../../Analytics/utils";
import { analyticsOpenTermsLinkAction } from "../../../Analytics/actions";
import type { AppState } from "../../../../../common/types";
import { selectCurrentLanguage } from "../../../../../common/selectors";

const useStyles = makeStyles(
    (theme) => ({
        analyticsTerms: {
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0.4px",
            color: theme.palette.custom.colorGray_8a,
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                textAlign: "left",
            },
        },
    }),
    { name: "AnalyticsWidgetTermsMessage" },
);

type AnalyticsWidgetTermsMessageProps = {
    currentLanguage: string;
    dispatch: Dispatch;
};

const AnalyticsWidgetTermsMessageComponent = ({ currentLanguage, dispatch }: AnalyticsWidgetTermsMessageProps) => {
    const intl = useIntl();
    const classes = useStyles();

    const handleTermsLinkClick = () => {
        dispatch(analyticsOpenTermsLinkAction());
    };

    return (
        <Typography className={classes.analyticsTerms}>
            {intl.msgJoint("msg: analyticsTab.acceptTerms {By continuing you accept that we share information with Kliken, our service provider. See their}")}
            {" "}
            <Link
                href={makeKlikenUrl(currentLanguage, 'terms-of-service.html')}
                target="_blank"
                onClick={handleTermsLinkClick}
            >
                {intl.msgJoint("msg: common.termsAndConditions {terms and conditions}")}{"."}
            </Link>
        </Typography>
    );
};

const mapStateToProps = (state: AppState) => ({
    currentLanguage: selectCurrentLanguage(state),
});

export const AnalyticsWidgetTermsMessage = connect(mapStateToProps)(AnalyticsWidgetTermsMessageComponent);
